import { SimulatedClassifiedTimeFields } from 'API/ClassifiedTime/GraphQL/Fragments/types'
import { DateTime, Duration } from 'luxon'

import { SimulationDataItem, SimulationTotalItem } from '../types'

const calculateDuration = (
  startAt: string,
  endAt: string,
  timezone: string,
): Duration => {
  const start = DateTime.fromISO(startAt, { zone: timezone }).set({
    second: 0,
    millisecond: 0,
  })
  const end = DateTime.fromISO(endAt, { zone: timezone }).set({
    second: 0,
    millisecond: 0,
  })

  return end.diff(start, ['hours', 'minutes'])
}

// ==============================
// Data
// ==============================

export function processSimulationData(
  data: SimulatedClassifiedTimeFields[],
  timezone: string,
) {
  const classifiedTimesByDate = data.reduce<
    Record<string, SimulatedClassifiedTimeFields[]>
  >((acc, item) => {
    const date = DateTime.fromISO(item.date).toISODate()
    acc[date] = [...(acc[date] || []), item]

    return acc
  }, {})

  return processSimulationDataRows(classifiedTimesByDate, timezone)
}

function processSimulationDataRows(
  classifiedTimesByDate: Record<string, SimulatedClassifiedTimeFields[]>,
  timezone: string,
) {
  const processedData: SimulationDataItem[] = Object.entries(
    classifiedTimesByDate,
  ).map(([date, classifiedTimes]) => ({
    date,
    rows: classifiedTimes.map(item => {
      const duration = calculateDuration(item.startAt, item.endAt, timezone)

      return {
        timeTypeName: item.timetype?.name,
        color: item.timetype?.color,
        startAt: DateTime.fromISO(item.startAt, {
          zone: timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE),
        endAt: DateTime.fromISO(item.endAt, {
          zone: timezone,
        }).toLocaleString(DateTime.TIME_SIMPLE),
        duration: duration.toFormat('hh:mm'),
      }
    }),
  }))

  return processedData
}

// ==============================
// Total
// ==============================

export function processSimulationTotalData(
  data: SimulatedClassifiedTimeFields[],
  timezone: string,
): SimulationTotalItem[] {
  const totalDataByTimeTypeId = data.reduce<
    Record<string, SimulationTotalItem & { totalDuration: Duration }>
  >((acc, item) => {
    if (!item.timetype) return acc

    const timeTypeId = item.timetype.id
    const timeTypeName = item.timetype?.name
    const color = item.timetype?.color
    const duration = calculateDuration(item.startAt, item.endAt, timezone)

    const totalDuration = acc[timeTypeId]?.totalDuration
      ? acc[timeTypeId].totalDuration.plus(duration)
      : duration

    acc[timeTypeId] = {
      name: timeTypeName,
      color,
      totalDuration,
      duration: totalDuration.toFormat('hh:mm'),
    }

    return acc
  }, {})

  return Object.values(totalDataByTimeTypeId)
}
