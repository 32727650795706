import { useCallback } from 'react'

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { AcceptShiftCoverMutation, ShiftCoversByCursorQuery } from './GraphQL'

export function useAcceptShiftCover() {
  const t = useI18n<
    typeof TRANSLATIONS.shiftTradeCover.toasts.shiftCover.accept
  >('shiftTradeCover.toasts.shiftCover.accept')

  const [innerAccept, { loading: accepting, error }] = useApolloMutation<
    MutationData<'acceptShiftCover'>,
    Gateway.MutationAcceptShiftCoverArgs
  >(AcceptShiftCoverMutation, {
    refetchQueries: compact([getOperationName(ShiftCoversByCursorQuery)]),
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const acceptShiftCover = useCallback(
    async (id: string) => {
      const result = await innerAccept({
        variables: {
          input: {
            shiftCoverIdentifier: {
              shiftCoversEmployeeId: id,
            },
          },
        },
      })

      return Boolean(result.errors)
    },
    [innerAccept],
  )

  return { acceptShiftCover, accepting, acceptError: error }
}
