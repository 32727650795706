import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Control,
  useController,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'

import { isEqual, pick } from 'lodash'

import { FormEarningTypePicker } from 'components/blocks/__v3__/HookForm/FormEarningTypePicker'
import { FormShiftTagsPickerPopover } from 'components/blocks/__v3__/HookForm/FormShiftTagsPickerPopover'
import { FormTextAreaPopover } from 'components/blocks/__v3__/HookForm/FormTextAreaPopover'
import { FormTimeBucketPicker } from 'components/blocks/__v3__/HookForm/FormTimeBucketPicker'
import { FormTimeTypesPicker } from 'components/blocks/__v3__/HookForm/FormTimeTypesPicker'
import { MEAL_BREAK_OPTIONS } from 'components/blocks/__v3__/Timesheet/components/TimesheetRecord/constants'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { InputLabel, LabelText } from 'components/ui/__v3__'
import { FormSelect, FormTimeSelect } from 'components/ui/__v3__/HookForm'
import { Error } from 'components/ui/__v3__/HookForm/components'

import { TimeEntryState } from 'constants/gatewayGraphQL'

import {
  useAppContext,
  useI18n,
  useLocale,
  useTimeBucketPermissions,
} from 'hooks'
import usePrevious from 'hooks/usePrevious'

import { i18n, TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import {
  DurationValue,
  HelperAction,
  HelperStatus,
  TimeEntryStatus,
} from './styles'

import { draftTimesheetRecordGuard, secondsDuration } from '../../helpers'
import { PositionOption, TimesheetFormState } from '../../types'
import { CheckIcon, Timesheet } from '../styles'

type Props = {
  disabled: boolean
  control: Control<TimesheetFormState>
  index: number
  trigger: UseFormTrigger<TimesheetFormState>
  positionOptions: PositionOption[]
  onRemoveDraft: (index: number) => void
  onUndoChanges: (index: number) => void
  onDeleteExisting: (id: number) => void
}

export function TimesheetRecord({
  disabled,
  control,
  positionOptions,
  index,
  trigger,
  onRemoveDraft,
  onDeleteExisting,
  onUndoChanges,
}: Props) {
  const { company } = useAppContext()
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets>('weeklyTimesheets')
  const locale = useLocale()
  const name = `entries.${index}` as const

  const {
    canViewSelectTimeBuckets,
    canModifySelectTimeBuckets,
  } = useTimeBucketPermissions()

  const {
    fieldState: { error, isDirty },
  } = useController({
    control,
    name,
  })

  const timesheetRecord = useWatch({
    control,
    name,
  })

  const draft = draftTimesheetRecordGuard(timesheetRecord)

  // ====================================================================
  // Note: this is a workaround to error name update when startSeconds or endSeconds change, there should be a better way
  const startEnd = pick(timesheetRecord, ['startSeconds', 'endSeconds'])
  const prevStartEnd = usePrevious(
    pick(timesheetRecord, ['startSeconds', 'endSeconds']),
  )
  useEffect(() => {
    if (!isEqual(startEnd, prevStartEnd)) {
      trigger()
    }
  }, [trigger, prevStartEnd, startEnd])

  // ====================================================================
  const {
    submittedAt,
    state,
    startSeconds,
    endSeconds,
    position,
  } = timesheetRecord

  const handleDeleteClick = useCallback((id: number) => onDeleteExisting(id), [
    onDeleteExisting,
  ])

  const timezoneCode = useMemo(
    () =>
      position
        ? Utils.DateTime.zoneOffsetName(position.data.location.timezone, locale)
        : '',
    [locale, position],
  )

  const submitted = !!submittedAt

  const renderHelper = useCallback(() => {
    if (draft) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.draft')}</Span>

          {!disabled && (
            <HelperAction onClick={() => onRemoveDraft(index)}>
              {t('helper.actions.remove')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    if (!draft && isDirty) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.modified')}</Span>

          {!disabled && (
            <HelperAction onClick={() => onUndoChanges(index)}>
              {t('helper.actions.undo')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    if (!draft && !submitted) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.unsubmitted')}</Span>

          {!disabled && (
            <HelperAction onClick={() => handleDeleteClick(timesheetRecord.id)}>
              {t('helper.actions.delete')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }
    // Gateway.TimeEntryState
    if (state) {
      return (
        <HelperStatus>
          <Flex alignItems="center" gap={2}>
            <TimeEntryStatus state={state}>
              {/* @ts-ignore */}
              {t(`helper.statuses.${state.toLowerCase()}`)}
            </TimeEntryStatus>

            {state === TimeEntryState.Approved && <CheckIcon />}
          </Flex>

          {!disabled && (
            <HelperAction onClick={() => handleDeleteClick(timesheetRecord.id)}>
              {t('helper.actions.delete')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    return <></>
  }, [
    disabled,
    draft,
    handleDeleteClick,
    index,
    isDirty,
    onRemoveDraft,
    onUndoChanges,
    state,
    submitted,
    t,
    timesheetRecord.id,
  ])

  return (
    <Timesheet.Container>
      <Timesheet.Form>
        <FormSelect
          control={control}
          isClearable={false}
          isDisabled={disabled}
          labelText={t('fields.position')}
          name={`entries.${index}.position`}
          options={positionOptions}
        />

        <FormTimeSelect
          control={control}
          disabled={disabled}
          labelText={`${t('fields.startTime')} (${timezoneCode})`}
          // @ts-ignore
          minWidth={124}
          name={`entries.${index}.startSeconds`}
          // @ts-ignore
          width={124}
        />

        <FormTimeSelect
          control={control}
          disabled={disabled}
          labelText={`${t('fields.endTime')} (${timezoneCode})`}
          // @ts-ignore
          minWidth={124}
          // @ts-ignore
          name={`entries.${index}.endSeconds`}
          width={124}
        />

        <div>
          <InputLabel>
            <LabelText labelText={t('fields.duration')} />
          </InputLabel>
          <DurationValue>
            {Utils.DateTime.formatDuration(
              secondsDuration(startSeconds, endSeconds),
            )}
          </DurationValue>
        </div>

        {company.identity.Aldo ? (
          <FormEarningTypePicker
            control={control}
            isClearable
            isDisabled={disabled}
            labelText={t('fields.timeType')}
            name={`entries.${index}.timeType`}
          />
        ) : (
          <FormTimeTypesPicker
            control={control}
            disabled={disabled}
            labelText={t('fields.timeType')}
            name={`entries.${index}.timeType`}
          />
        )}

        <FormSelect
          control={control}
          isClearable
          isDisabled={disabled}
          labelText={t('fields.mealBreak')}
          name={`entries.${index}.mealBreak`}
          options={MEAL_BREAK_OPTIONS}
        />

        <FormShiftTagsPickerPopover
          control={control}
          disabled={disabled}
          labelText={t('fields.shiftTags')}
          name={`entries.${index}.shiftTags`}
        />

        {canViewSelectTimeBuckets && (
          <FormTimeBucketPicker
            control={control}
            isClearable
            isDisabled={!canModifySelectTimeBuckets || disabled}
            isSearchable
            labelText={i18n('common.costGroupSubGroup')}
            name={`entries.${index}.timeBucketChild`}
          />
        )}

        <FormTextAreaPopover
          control={control}
          disabled={disabled}
          labelText={t('fields.comments')}
          name={`entries.${index}.activityDetails`}
        />
      </Timesheet.Form>

      <Timesheet.Footer>
        {renderHelper()}
        {/* @ts-ignore */}
        {error && <Error error={error} />}
      </Timesheet.Footer>
    </Timesheet.Container>
  )
}
