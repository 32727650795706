import { SchedulePickerFields } from 'API/Schedule/GraphQL/Fragments/types'

import { formatShiftDetailsToLabel } from './formatters'

import { ScheduleOption } from '../types'

export function scheduleToOption(
  schedule: SchedulePickerFields,
): ScheduleOption {
  const { shift } = schedule

  const label = formatShiftDetailsToLabel({
    name: shift.name,
    range: shift.range,
    timezone: shift.location.settings.timezone,
  })

  return {
    id: schedule.id,
    value: schedule.id,
    label,
    schedule,
  }
}
