import styled from 'styled-components'
import { justifyContent, JustifyContentProps } from 'styled-system'

import { COLUMN_WIDTH } from '../../constants'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const DateRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.SLATE_GRAY};
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.WHISPER_GRAY};
`

export const DateText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  color: ${({ theme }) => theme.colors.EBONY_CLAY};
`

export const DataRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${COLUMN_WIDTH}px);
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.WHISPER_GRAY};
`

export const DataRowCell = styled.div<JustifyContentProps>`
  height: 100%;
  display: flex;
  gap: ${({ theme }) => theme.space[1]}px;
  padding: 10px 8px;
  align-items: center;
  line-height: 14px;

  ${justifyContent}
`
