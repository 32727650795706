import { StoreonModule } from 'storeon'

import { INIT } from 'services/StoreOn/constants'

import { PEOPLE_KEY, TOGGLE_ROLES_TABLE_VIEW_EVENT } from './constants'
import { PeopleEvents, PeopleState } from './types'

const INITIAL_STATE: PeopleState = {
  [PEOPLE_KEY]: {
    isRolesTableView: false,
  },
}

export const peopleModule: StoreonModule<PeopleState, PeopleEvents> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(TOGGLE_ROLES_TABLE_VIEW_EVENT, (state, value) => ({
    ...state,
    [PEOPLE_KEY]: {
      ...state[PEOPLE_KEY],
      isRolesTableView: value,
    },
  }))
}
