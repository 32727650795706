import React from 'react'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { Container, Title } from './styles'

export function Header() {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.simulationModal>(
    'weeklyTimesheets.simulationModal',
  )

  return (
    <Container>
      <Title>{t('data.header.timeType')}</Title>
      <Title>{t('data.header.start')}</Title>
      <Title>{t('data.header.end')}</Title>
      <Title>{t('data.header.duration')}</Title>
    </Container>
  )
}
