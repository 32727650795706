/* eslint-disable no-console */
declare const self: DedicatedWorkerGlobalScope

export default function SessionWorker() {
  // Note: This is a shared worker, so we need to define the message types here
  // or they will be undefined in the worker scope
  enum WorkerMessageType {
    START = 'START',
    TIMEOUT = 'TIMEOUT',
    UPDATE = 'UPDATE',
    DISCONNECT = 'DISCONNECT',
  }
  type WorkerMessage = {
    type: WorkerMessageType
    payload?: {
      duration?: number
    }
  }

  let sessionDuration = 0
  let checkIntervalId: NodeJS.Timeout | null = null
  const CHECK_INTERVAL = 1000 // 1 second

  const startSessionCheck = () => {
    if (checkIntervalId) {
      clearInterval(checkIntervalId)
    }
    const expiresAt = Date.now() + sessionDuration * 1000

    checkIntervalId = setInterval(() => {
      if (Date.now() >= expiresAt) {
        self.postMessage({
          type: WorkerMessageType.TIMEOUT,
        })
      }
    }, CHECK_INTERVAL)
  }

  self.onmessage = (event: MessageEvent<WorkerMessage>) => {
    const { type, payload } = event.data

    switch (type) {
      case WorkerMessageType.START:
      case WorkerMessageType.UPDATE:
        sessionDuration = payload?.duration ?? 0
        startSessionCheck()
        break

      case WorkerMessageType.DISCONNECT:
        if (checkIntervalId) {
          clearInterval(checkIntervalId)
          checkIntervalId = null
        }
        break

      default:
        break
    }
  }
}
