import { FullStoryAPI } from 'react-fullstory'
import { toastr } from 'react-redux-toastr'

import { call, put, race, select, take } from 'redux-saga/effects'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { misc } from 'constants/config'
import { EMPLOYEE_STATE_FILTERS } from 'constants/employees'
import { ACCESS_LEVEL } from 'constants/ids'

import { buildFullName } from 'helpers/employee'
import { waitForTypes } from 'helpers/sagas'

import { AuthService } from 'services/Auth'

import { completeRefetch } from 'store/actions/app'
import { AUTHENTICATE } from 'store/actions/auth'
import { CREATE_COMPANY, createCompany } from 'store/actions/company/companies'
import {
  LOAD_EMPLOYEE_BY_ID_SILENT,
  loadEmployee,
} from 'store/actions/employees/employees'
import {
  LOAD_COUNTERS,
  LOAD_VIEWER,
  LOAD_VIEWER_BRANCHES,
  LOAD_VIEWER_COMPANY,
  loadCounters,
  loadViewer,
  loadViewerBranches,
  loadViewerCompany,
  setBranch,
  setCompany,
} from 'store/actions/viewer'
import {
  getAccessLevel,
  getAsEmployee,
  getBranch,
  getIsLoaded,
  getIsViewerArchivedInActiveBranch,
  getViewer,
} from 'store/selectors/viewer'

function* fetchResources() {
  const accessLevel = yield select(getAccessLevel)
  const asEmployee = yield select(getAsEmployee)

  if (
    accessLevel === ACCESS_LEVEL.admin ||
    accessLevel === ACCESS_LEVEL.owner ||
    accessLevel === ACCESS_LEVEL.manager
  ) {
    if (asEmployee) {
      yield loadEmployeeApp()
    } else {
      yield loadEmployerApp()
    }
  } else {
    yield loadEmployeeApp()
  }

  yield put(completeRefetch())

  const viewer = yield select(getViewer)

  try {
    FullStoryAPI('identify', viewer.id, {
      fullName: buildFullName(viewer),
    })
  } catch (e) {
    // eslint-disable-next-line
    console.log('FullStory error:', e)
  }
}

function* loadEmployeeApp() {
  yield put(loadViewerCompany())
  yield put(loadViewerBranches())
  yield put(loadCounters())

  const viewer = yield select(getViewer)
  const employeeId = get(viewer, ['employees', 0, 'id'])

  if (employeeId) {
    yield put(loadEmployee(employeeId))
  }

  yield waitForTypes([
    LOAD_VIEWER_COMPANY.SUCCESS,
    LOAD_VIEWER_BRANCHES.SUCCESS,
    LOAD_COUNTERS.SUCCESS,
    LOAD_EMPLOYEE_BY_ID_SILENT.SUCCESS,
  ])

  const branch = yield select(getBranch)
  const companyId = get(branch, 'company.id')

  if (companyId) {
    yield put(setCompany(companyId))
  }

  const isViewerArchivedInActiveBranch = yield select(
    getIsViewerArchivedInActiveBranch,
  )

  if (isViewerArchivedInActiveBranch) {
    yield call(updateArchivedEmployeeActiveBranch, viewer)
  }
}

function* updateArchivedEmployeeActiveBranch(viewer) {
  const activeEmployee = find(
    viewer.employees,
    employee => employee.state === EMPLOYEE_STATE_FILTERS.active,
  )
  if (!isEmpty(activeEmployee)) {
    const availableBranchId = get(activeEmployee, 'branch.id')
    yield put(setBranch(availableBranchId))
  }
}

function* loadEmployerApp() {
  yield put(loadViewerCompany())

  const { companySuccess } = yield race({
    companySuccess: take(LOAD_VIEWER_COMPANY.SUCCESS),
    companyFailure: take(LOAD_VIEWER_COMPANY.FAILURE),
  })

  if (!companySuccess) {
    if (misc.disableBilling) {
      yield put(createCompany('test company', '1'))
      yield take(CREATE_COMPANY.SUCCESS)
      yield fetchResources()
    } else {
      yield AuthService.logout()
    }
  } else {
    yield put(loadCounters())

    yield waitForTypes([LOAD_COUNTERS.SUCCESS])
  }
}

export default function* restoreSession() {
  while (true) {
    yield take([AUTHENTICATE.SUCCESS])

    const dataLoaded = yield select(getIsLoaded)

    if (dataLoaded) {
      yield fetchResources()
    } else {
      yield put(loadViewer())

      const { success } = yield race({
        success: take(LOAD_VIEWER.SUCCESS),
        failure: take(LOAD_VIEWER.FAILURE),
      })

      if (success) {
        yield fetchResources()
      } else {
        yield AuthService.logout()
        toastr.error('Wrong email or password', {
          disableCloseButtonFocus: true,
        })
      }
    }
  }
}
