import { useCallback, useMemo, useState } from 'react'

import {
  isNetworkRequestInFlight,
  NetworkStatus,
} from '@apollo/client/core/networkStatus'
import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { RelayStylePaginationVariables } from 'services/Apollo/types'

import {
  EmployeeJobsWithEmploymentTermsByCursorQuery,
  EmployeeJobsWithEmploymentTermsByCursorQueryData,
} from './GraphQL'

export function useEmployeeJobsWithEmploymentTermsByCursor(
  {
    page,
    filter,
    sorting,
    enabled = true,
    refetchWithLoading = true,
    disableMerge = false,
  }: {
    page: CursorPage
    filter: Gateway.EmployeeJobFilter
    sorting: Gateway.EmployeeJobSorting[]
    enabled?: boolean
    refetchWithLoading?: boolean
    disableMerge?: boolean
  },
  queryOptions?: Partial<Omit<QueryHookOptions, 'skip' | 'variables'>>,
) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, fetchMore, networkStatus } = useApolloQuery<
    EmployeeJobsWithEmploymentTermsByCursorQueryData,
    Gateway.QueryEmployeeJobsByCursorArgs & RelayStylePaginationVariables
  >(EmployeeJobsWithEmploymentTermsByCursorQuery, {
    ...queryOptions,
    fetchPolicy: 'network-only',
    nextFetchPolicy: disableMerge ? 'network-only' : 'cache-first',
    skip: !enabled,
    variables: {
      sorting,
      filter,
      paging: cursorPageToGraphqlPaging(page),
      disableMerge,
    },
    onCompleted(data) {
      const newPageMeta = data?.employeeJobsByCursor?.pageInfo
      if (!newPageMeta) return

      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const employeeJobsFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, page.size, fetchMore])

  const isLoading = refetchWithLoading
    ? isNetworkRequestInFlight(networkStatus)
    : networkStatus === NetworkStatus.loading

  const employeeJobs = useMemo(
    () => data?.employeeJobsByCursor?.edges.map(edge => edge.node) ?? [],
    [data?.employeeJobsByCursor],
  )

  return {
    employeeJobs,
    employeeJobsLoading: isLoading,
    employeeJobsRefetch: refetch,
    employeeJobsFetchMore,
    employeeJobsPageMeta: currentPageMeta,
  }
}
