import styled, { css } from 'styled-components'
import {
  flexbox,
  height,
  HeightProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

import { ColumnMeta, RowData, TableMeta } from '@tanstack/react-table'

import { Flex, Span } from 'components/ui/__v2__/Grid'

interface TableContainerProps extends HeightProps {
  width?: string
  maxHeight?: string
  borderCollapse?: string
}

type MetaProps = ColumnMeta<RowData, any> & TableMeta<RowData>

type TrProps = {
  expanded?: boolean
  borderBottomHighlighted?: boolean
  borderTopHighlighted?: boolean
}

const expandedCss = ({ expanded }: TrProps) =>
  expanded &&
  css`
    background-color: ${({ theme }) => theme.colors.PROVINCIAL_PINK};
    border-bottom: none;
  `

const borderBottomHighlightedCss = ({ borderBottomHighlighted }: TrProps) =>
  borderBottomHighlighted &&
  css`
    border-bottom: 1px solid ${({ theme }) => theme.layout.primary};

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.layout.primary};
    }
  `

const borderTopHighlightedCss = ({ borderTopHighlighted }: TrProps) =>
  borderTopHighlighted &&
  css`
    border-top: 1px solid ${({ theme }) => theme.layout.primary};
  `

export const TableContainer = styled.div<TableContainerProps>`
  overflow: auto;
  width: ${props => props.width ?? '100%'};
  max-height: ${props => props.maxHeight ?? '100%'};
  border-collapse: ${props => props.borderCollapse ?? 'initial'};

  ${height}
`

interface DataTableContainerProps {
  height?: number | string
}
export const DataTable = styled.table<DataTableContainerProps>`
  width: 100%;
  border-collapse: collapse;
`

export const TableHeaderContainer = styled.div`
  height: 80px;
  padding: 16px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.table.border.color};
`

export const TableHeaderTitle = styled.h2`
  font-size: 16px;
`

export const Thead = styled.thead`
  & > tr > th {
    z-index: ${({ theme }) => theme.zIndices.stickyHeader};
  }
`

export const TFoot = styled.tfoot`
  font-weight: bold;
  border-top: 1.5px solid ${({ theme }) => theme.layout.darkBorder};
  & > tr > td {
    z-index: ${({ theme }) => theme.zIndices.stickyHeader};
  }
`

export const Tr = styled.tr<TrProps>`
  vertical-align: top;
  border-bottom: 1px solid ${({ theme }) => theme.table.border.color};

  &:last-child {
    border-bottom: none;
  }

  ${expandedCss}
  ${borderBottomHighlightedCss}
  ${borderTopHighlightedCss}
`

const actionableCss = ({ actionable }: MetaProps) =>
  actionable
    ? css`
        :last-of-type {
          position: sticky;
          top: 0;
          right: 0;
          background-color: ${({ theme }) => theme.layout.bg};

          width: 0.1%; // To make it shrink to content

          box-shadow: inset 2px -1px 0 ${({ theme }) => theme.table.border.color};
        }
        :nth-last-of-type(2) {
          border-right: unset;
        }
      `
    : ''

const onlyHorizontalDividersCss = ({ onlyHorizontalDividers }: MetaProps) =>
  onlyHorizontalDividers &&
  css`
    &:not(:last-child) {
      border-right: none;
    }
  `

const verticalAlignInitialEnabledCss = (enabled?: boolean) =>
  enabled &&
  css`
    vertical-align: initial;
  `

const backgroundColorCss = (color?: string) =>
  color &&
  css`
    background-color: ${color};
  `

export const Th = styled.th<MetaProps>`
  padding: ${({ slim }) => (slim ? '4px 12px' : '12px 16px')};

  position: sticky;
  top: 0;

  text-align: start;
  vertical-align: top;

  background-color: ${({ theme, contrastHeader }) =>
    contrastHeader ? theme.layout.grayBg : theme.layout.bg};
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.table.border.color};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.table.border.color};
  }

  ${flexbox};
  ${layout};
  ${typography}

  ${actionableCss}
  ${onlyHorizontalDividersCss}

  ${({ headerBackgroundColor }) => backgroundColorCss(headerBackgroundColor)}
  ${({ onlyBodyVerticalAlign }) =>
    verticalAlignInitialEnabledCss(onlyBodyVerticalAlign)}
`

export const HeaderIconContainer = styled(Flex)`
  height: 14px;
  width: 14px;
  padding: 0;

  background: none;
  color: ${({ theme }) => theme.table.headerIcon.color};
`

export const Td = styled.td<MetaProps>`
  position: relative;
  padding: ${({ slim }) => (slim ? '4px 12px' : '12px 16px')};

  white-space: ${({ whiteSpace }) => whiteSpace ?? 'nowrap'};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.table.border.color};
  }

  overflow: hidden;
  text-overflow: ellipsis;

  ${flexbox};
  ${layout};
  ${typography}

  ${actionableCss}
  ${onlyHorizontalDividersCss}


  ${({ cellBackgroundColor }) => backgroundColorCss(cellBackgroundColor)}
  ${({ onlyHeaderVerticalAlign }) =>
    verticalAlignInitialEnabledCss(onlyHeaderVerticalAlign)}
`

export const Tf = styled.tfoot<MetaProps>`
  padding: ${({ slim }) => (slim ? '12px 16px 0' : '6px 12px 0')};
  text-align: start;
`
export const TableLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  height: 100%;
`

export const EmptyData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`

export type TitleStyleProps = LayoutProps & SpaceProps & TypographyProps
export const TableTitle = styled.div<TitleStyleProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  white-space: nowrap;

  margin-left: 16px;
  ${layout}
  ${space}
  ${typography}
`

export const SortableTitle = styled(Span)`
  transition: color 0.2s;
  :hover {
    color: ${({ theme }) => theme.table.headerIcon.color};
  }
`
