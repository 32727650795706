import { useCallback, useEffect, useMemo, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { evictShifts } from './GraphQL/Updaters'
import {
  shiftFilterToAPI,
  ShiftsByCursorWithShiftJobsWithSchedulesQuery,
} from './GraphQL'
import { ShiftFilter, ShiftWithShiftJobsWithSchedulesAPIItem } from './types'

export function useShiftsByCursorWithShiftJobsWithSchedules({
  page,
  filter,
  sort,
  enabled = true,
}: {
  page: CursorPage
  filter: ShiftFilter
  sort: Gateway.ShiftSorting
  enabled?: boolean
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const [totalLoadedPageSize, setTotalLoadedPageSize] = useState<number>(
    page.size,
  )

  useEffect(() => {
    setTotalLoadedPageSize(page.size)
  }, [filter, page])

  const { data, refetch, fetchMore, client, networkStatus } = useApolloQuery<
    QueryData<'shiftsByCursor'>,
    Gateway.QueryShiftsByCursorArgs
  >(ShiftsByCursorWithShiftJobsWithSchedulesQuery, {
    fetchPolicy: 'network-only',
    // TODO: it's a temporary hack to make Apollo work with cached data without reloading, UPDATE APOLLO!
    nextFetchPolicy: 'cache-first',
    skip: !enabled,

    variables: {
      sorting: sort,
      filter: shiftFilterToAPI(filter),
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      if (!data?.shiftsByCursor) return

      const newPageMeta = data.shiftsByCursor.pageInfo

      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const shifts = useMemo(() => {
    const entities = data?.shiftsByCursor?.edges?.map(item => item.node) || []
    return entities as ShiftWithShiftJobsWithSchedulesAPIItem[]
  }, [data?.shiftsByCursor])

  const shiftsFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setTotalLoadedPageSize(prevState => prevState + page.size)
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  const shiftsRefetchWithTotalLoadedSize = useCallback(() => {
    refetch({
      paging: {
        limit: totalLoadedPageSize,
      },
    })
  }, [refetch, totalLoadedPageSize])

  const shiftsRefetch = useCallback(async () => {
    setTotalLoadedPageSize(page.size)
    return refetch()
  }, [page.size, refetch])

  const removeShiftsFromCache = useCallback(
    (ids: string[]) => {
      evictShifts(client.cache, ids)
    },
    [client],
  )

  const shiftsLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  return {
    shifts,
    shiftsLoading,
    shiftsFetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
    shiftsRefetch,
    shiftsRefetchWithTotalLoadedSize,
    shiftsFetchMore,
    shiftsPageMeta: currentPageMeta,
    removeShiftsFromCache,
  }
}
