import { useCallback } from 'react'

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { CreateShiftTradeMutation, ShiftTradesByCursorQuery } from './GraphQL'

export function useCreateShiftTrade() {
  const t = useI18n<
    typeof TRANSLATIONS.shiftTradeCover.toasts.shiftTrade.create
  >('shiftTradeCover.toasts.shiftTrade.create')

  const [innerCreate, { loading: creating, error }] = useApolloMutation<
    MutationData<'createShiftTrade'>,
    Gateway.MutationCreateShiftTradeArgs
  >(CreateShiftTradeMutation, {
    refetchQueries: compact([getOperationName(ShiftTradesByCursorQuery)]),
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createShiftTrade = useCallback(
    async (input: Gateway.CreateShiftTradeInput) => {
      const result = await innerCreate({
        variables: {
          input,
        },
      })

      return Boolean(result.errors)
    },
    [innerCreate],
  )

  return { createShiftTrade, creating, createError: error }
}
