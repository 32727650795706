import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { SchedulesGardaListViewQuery } from 'API/Schedule/GraphQL'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { UpdatePauseMutation } from './GraphQL'

export function useUpdatePause() {
  const t = useI18n<
    typeof TRANSLATIONS.shiftTradeCover.toasts.shiftTrade.reject
  >('schedule.garda.pauseTimers.toasts.updatePause')

  const [innerUpdatePause, { loading }] = useApolloMutation<
    MutationData<'updatePause'>,
    Gateway.MutationUpdatePauseArgs
  >(UpdatePauseMutation, {
    refetchQueries: compact([getOperationName(SchedulesGardaListViewQuery)]),
    silent: true,
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const updatePause = async (input: Gateway.UpdatePauseInput) => {
    const result = await innerUpdatePause({ variables: { input } })
    return graphQLResultHasError(result)
  }

  return { updatePause, updatingPause: loading }
}
