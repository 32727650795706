import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { useTheme } from 'styled-components'

import { Box } from 'components/ui/__v2__/Grid'
import {
  InputBlock,
  InputLabel,
  Switch,
  SwitchProps,
} from 'components/ui/__v3__/'

import { Error } from './components'
import { ControllerBaseProps } from './types'

type FormCheckboxProps<T extends FieldValues> = ControllerBaseProps<T> & {
  label?: string | React.ReactNode
  withInputBlock?: boolean
} & Omit<SwitchProps, 'onChange' | 'onBlur'>

export function FormSwitch<T extends FieldValues>({
  control,
  name,
  label,
  withInputBlock = false,
  ...rest
}: FormCheckboxProps<T>) {
  const theme = useTheme()

  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  if (withInputBlock) {
    return (
      <InputBlock
        display="flex"
        flexDirection="row"
        fontSize={14}
        fontWeight="normal"
      >
        <Switch
          {...rest}
          checked={value}
          mr={2}
          onBlur={onBlur}
          onChange={onChange}
        />
        <Box color={theme.layout.text}>{label}</Box>
        {error && <Error error={error} />}
      </InputBlock>
    )
  }

  return (
    <InputLabel
      display="flex"
      flexDirection="row"
      fontSize={14}
      fontWeight="normal"
    >
      <Switch
        {...rest}
        checked={value}
        mr={2}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Box color={theme.layout.text}>{label}</Box>
      {error && <Error error={error} />}
    </InputLabel>
  )
}
