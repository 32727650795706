import { ShiftLocationRangeFields } from 'API/Shift/GraphQL/Fragments/types'
import { DateTime } from 'luxon'

export function formatShiftDetailsToLabel({
  name,
  range,
  timezone,
}: {
  name: string
  range: ShiftLocationRangeFields['range']
  timezone: string
}) {
  const start = DateTime.fromISO(range.start)
    .setZone(timezone)
    .toLocaleString(DateTime.TIME_SIMPLE)

  const end = DateTime.fromISO(range.end)
    .setZone(timezone)
    .toLocaleString(DateTime.TIME_SIMPLE)

  return `${start} - ${end} / ${name}`
}
