import { useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { TimeoffsByCursorQuery, TimeoffssByCursorQueryData } from './GraphQL'

type Args = {
  filter?: Gateway.TimeoffsFilter
  paging: CursorPage
  sorting: [Gateway.TimeoffsSorting]
  skip?: boolean
}

export function useTimeoffsByCursorGql({
  filter,
  paging,
  sorting,
  skip,
}: Args) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, fetchMore, refetch } = useApolloQuery<
    TimeoffssByCursorQueryData,
    Gateway.QueryTimeoffsByCursorArgs
  >(TimeoffsByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
    },
    skip,
    onCompleted(data) {
      const newPageMeta = data.timeoffsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const loadMore = async () => {
    setLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setLoadingMore(false)
  }

  const timeoffs = data?.timeoffsByCursor?.edges.map(edge => edge.node) ?? []

  return {
    timeoffs,
    loading,
    refetch,
    loadMore,
    loadingMore,
    pageInfo: currentPageMeta,
  }
}
