import React, { ChangeEvent } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import MaskedInput, { maskArray } from 'react-text-mask'

import { Input } from 'components/ui/__v3__/Input'
import { InputProps } from 'components/ui/__v3__/Input/types'

import { Error } from './components'
import { ControllerBaseProps } from './types'

type MaskProps = {
  showMask?: boolean
  mask: maskArray | ((value: string) => maskArray) | undefined
  convertValueToNumber?: boolean
  hideErrorMessage?: boolean
}
export type FormMaskedNumericProps<
  T extends FieldValues
> = ControllerBaseProps<T> & InputProps & MaskProps

export function FormMaskedNumeric<T extends FieldValues>({
  name,
  mask,
  control,
  convertValueToNumber,
  showMask,
  placeholder,
  hideErrorMessage,
  ...rest
}: FormMaskedNumericProps<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, value, onBlur } = fieldProps

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (convertValueToNumber) {
      !e.target.value ? onChange(null) : onChange(Number(e.target.value))
    } else {
      onChange(e.target.value)
    }
  }

  return (
    <MaskedInput
      mask={mask}
      placeholder={placeholder}
      render={(innerRef, props: InputProps) => {
        return (
          <Input
            {...rest}
            {...props}
            errored={!!error}
            helper={error && !hideErrorMessage && <Error error={error} />}
            // @ts-ignore
            ref={innerRef}
          />
        )
      }}
      showMask={showMask}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
    />
  )
}
