import React, { useCallback } from 'react'

import { useApolloLazyQuery } from 'API/services/Apollo'
import { EarningTypesByOffsetQuery } from 'GraphQL/EarningType'
import { SelectOptionWithId } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import {
  EarningTypeSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZES } from 'constants/pagination'

import { createpDebounce } from 'helpers/debounce'

import useAppContext from 'hooks/useAppContext'
import { PaginationVariables } from 'hooks/useOffsetPagination'

import { i18n } from 'i18n'

import { earningTypeToOption, inputToFilter } from './helpers'
import { EarningTypePickerProps } from './types'

const paging: PaginationVariables['paging'] = {
  limit: PAGE_SIZES[0],
  offset: 0,
}

export function EarningTypePicker({
  isDisabled,
  placeholder,
  loadingText,
  value,
  onSelect,
  isClearable = false,
  withPortal = true,
  ...rest
}: EarningTypePickerProps) {
  const { company } = useAppContext()

  const [loadEarningTypes, { data, loading, refetch }] = useApolloLazyQuery<
    QueryData<'earningTypesByOffset'>,
    Gateway.QueryEarningTypesByOffsetArgs
  >(EarningTypesByOffsetQuery, {
    variables: {
      sorting: [
        {
          field: EarningTypeSortingField.Name,
          direction: SortingDirection.Asc,
        },
      ],
      paging,
    },
    fetchPolicy: 'network-only',
  })

  const toOption = earningTypeToOption(company.identity)

  const defaultOptions = data?.earningTypesByOffset?.rows.map(toOption) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(async (inputValue: string, callback: Function) => {
      // @ts-ignore // TODO: update Apollo from 3.3.20 and remove
      const refetchedData = await refetch({ filter: inputToFilter(inputValue) })
      const newOptions =
        refetchedData?.data?.earningTypesByOffset?.rows.map(toOption) ?? []

      callback(newOptions)
    }),
    [refetch],
  )

  const handleChange = (option: SelectOptionWithId) => {
    onSelect(option)
  }

  const renderLoadingMessage = () => {
    return loadingText ?? `${i18n('common.loading')}...`
  }

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect
      defaultOptions={defaultOptions}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={loading}
      isSearchable
      loadOptions={handleLoadOptions}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      value={value}
      withPortal={withPortal}
      onChange={handleChange}
      onMenuOpen={loadEarningTypes}
      {...rest}
    />
  )
}
