import React from 'react'

import { Header, Rows, Total } from './components'
import { Container } from './styles'

import { SimulationDataItem, SimulationTotalItem } from '../../types'

type Props = {
  isLoading: boolean
  data: SimulationDataItem[]
  totalData: SimulationTotalItem[]
}

export function SimulationData({ isLoading, data, totalData }: Props) {
  return (
    <Container>
      <Header />
      <Rows data={data} isLoading={isLoading} />
      {!isLoading && <Total data={totalData} />}
    </Container>
  )
}
