import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import { IS_PRODUCTION } from 'constants/config'

import { authLink, errorLink, requestLink } from './Links'
import { relayStylePagination } from './utils'

const links = [authLink(), requestLink(), errorLink()]
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        schedulesByCursor: relayStylePagination(['filter', 'sorting']),
        shiftsByCursor: relayStylePagination(['filter']),
        shiftJobsByCursor: relayStylePagination(['filter']),
        timeEntrySummariesByCursor: relayStylePagination(['filter']),
        employeeJobsByCursor: relayStylePagination(['filter']),
        timeBucketParentsByCursor: relayStylePagination(['filter']),
        timeBucketChildrenByCursor: relayStylePagination(['filter']),
        employmentTypesByCursor: relayStylePagination(['filter']),
        employmentLevelsByCursor: relayStylePagination(['filter']),
        autoSchedulingTasksByCursor: relayStylePagination([
          'filter',
          'sorting',
        ]),
        classifiersByCursorQuery: relayStylePagination(['filter']),
        shiftTradeSuggestedSchedulesByCursor: relayStylePagination(['filter']),
        // TODO: shifts logs merger
      },
    },
  },
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from(links),
  connectToDevTools: !IS_PRODUCTION,
  cache,
})
