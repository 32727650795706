import { useCallback, useState } from 'react'

import { CursorPage } from 'Types/common'

import { PaginationSizeOptions } from 'constants/pagination'

export function useCursorPagination(size: PaginationSizeOptions = 10) {
  const [page, setPage] = useState<CursorPage>({ size })

  const onReset = useCallback(() => {
    setPage({ size })
  }, [size])

  const onShowAfter = useCallback((currentEndCursor?: string | null) => {
    if (currentEndCursor) {
      setPage(prevState => ({
        ...prevState,
        startingAfter: currentEndCursor,
        endingBefore: null,
      }))
    }
  }, [])

  const onShowBefore = useCallback((currentStartCursor?: string | null) => {
    if (currentStartCursor) {
      setPage(prevState => ({
        ...prevState,
        startingAfter: null,
        endingBefore: currentStartCursor,
      }))
    }
  }, [])

  const onSizeChange = useCallback(
    (size: PaginationSizeOptions) => setPage({ size }),
    [],
  )

  return {
    onReset,
    onShowAfter,
    onShowBefore,
    onSizeChange,
    page,
  }
}
