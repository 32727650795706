import React, { useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { TimeoffsByCursorNode } from 'API/Timeoffs/GraphQL'
import { DateTime } from 'luxon'

import { compact, uniqBy } from 'lodash'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, SpacedColumn, SpacedRow, Span } from 'components/ui/__v2__/Grid'
import { Color } from 'components/ui/__v3__/Badge/TimetypeBadge'

import { useAppContext, useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import { TimeType } from './styles'

import {
  dayToInterval,
  entriesIntersectionWithDay,
  entriesToIntervals,
  timeoffsDuration,
  timeOffTimeTypeToOption,
  timeSheetItemsDuration,
} from '../../helpers'
import { TimesheetFormState } from '../../types'

type Props = {
  control: Control<TimesheetFormState>
  days: DateTime[]
  timeoffsByDay: TimeoffsByCursorNode[][]
}

export function TimesheetSummary({ control, days, timeoffsByDay }: Props) {
  const { company } = useAppContext()
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.summary>(
    'weeklyTimesheets.summary',
  )

  const timeoffs = useMemo(() => timeoffsByDay.flat(), [timeoffsByDay])
  const watched = useWatch({ control, name: 'entries' })

  // ============================== Time Types ==============================================

  const timeTypes = useMemo(() => {
    const timeEntryTimeTypes = compact(watched.map(entry => entry.timeType))
    const timeoffTimeTypes = compact(timeoffs.map(timeOffTimeTypeToOption))
    return uniqBy(timeEntryTimeTypes.concat(timeoffTimeTypes), 'value')
  }, [timeoffs, watched])

  const secondsByTimeType = useMemo(() => {
    return timeTypes
      .map(({ value: timeTypeId, label: timeTypeName, color }) => {
        const timeEntrySeconds = timeSheetItemsDuration(
          (watched ?? []).filter(
            ({ timeType }) => timeType?.value === timeTypeId,
          ),
        )
        const timeoffSeconds = timeoffsDuration(
          timeoffs.filter(
            timeoff =>
              timeoff.customLeaveDay.defaultTimeType?.id === timeTypeId,
          ),
        )
        return [timeTypeName, timeEntrySeconds + timeoffSeconds, color] as const
      })
      .sort((a, b) => a[0].localeCompare(b[0]))
  }, [timeTypes, timeoffs, watched])

  // ========================================================================================

  // ============================== Earning Types ===========================================

  const earningTypes = useMemo(
    () => uniqBy(compact(watched.map(entry => entry.earningType)), 'id'),
    [watched],
  )

  const secondsByEarningType = useMemo(
    () =>
      earningTypes
        .map(({ value: earningTypeId, label: earningTypeName }) => {
          return [
            earningTypeName,
            timeSheetItemsDuration(
              watched.filter(
                entry => entry.earningType?.value === earningTypeId,
              ),
            ),
          ] as const
        })
        .sort((a, b) => a[0].localeCompare(b[0])),
    [earningTypes, watched],
  )

  // ========================================================================================

  const secondsByDay = useMemo(
    () =>
      days.map((day, idx) => {
        const timeEntriesDuration = entriesIntersectionWithDay(
          entriesToIntervals(watched),
          dayToInterval(day),
        ).reduce((acc, interval) => {
          return acc + interval.length('seconds')
        }, 0)

        const timeoffsDuration = timeoffsByDay[idx].reduce((acc, timeoff) => {
          return acc + timeoff.quantity.hours
        }, 0)

        return (
          timeEntriesDuration + Utils.DateTime.hoursToSeconds(timeoffsDuration)
        )
      }),
    [days, timeoffsByDay, watched],
  )

  const totalSeconds = useMemo(() => {
    const timeoffsDuration = Utils.DateTime.hoursToSeconds(
      timeoffs.reduce((acc, timeoff) => {
        return acc + timeoff.quantity.hours
      }, 0),
    )
    return timeSheetItemsDuration(watched) + timeoffsDuration
  }, [watched, timeoffs])

  const showTimeTypesSummary =
    !company.identity.Aldo && secondsByTimeType.length > 0
  const showEarningTypesSummary =
    company.identity.Aldo && secondsByEarningType.length > 0

  return (
    <SpacedColumn>
      <SpacedRow>
        <Span fontWeight="bold">{t('totalHours')}:</Span>
        <Span>{Utils.DateTime.formatDuration(totalSeconds)}</Span>
      </SpacedRow>
      <Divider />
      {days.map((day, index) => (
        <SpacedRow key={day.toISO()}>
          <Span fontWeight="bold">{day.toFormat('EEEE')}:</Span>
          <Span>{Utils.DateTime.formatDuration(secondsByDay[index])}</Span>
        </SpacedRow>
      ))}

      {(showEarningTypesSummary || showTimeTypesSummary) && (
        <TimeType.Container>
          <TimeType.Title>{t('timeType')}</TimeType.Title>
          {showEarningTypesSummary &&
            secondsByEarningType.map(([earningTypeName, seconds]) => (
              <SpacedRow key={earningTypeName}>
                <TextOverflowWithPopover maxWidth="90px">
                  <TimeType.Title>{earningTypeName}</TimeType.Title>
                </TextOverflowWithPopover>
                <TimeType.Value>
                  {Utils.DateTime.formatDuration(seconds)}
                </TimeType.Value>
              </SpacedRow>
            ))}

          {showTimeTypesSummary &&
            secondsByTimeType.map(([timeTypeName, seconds, color]) => (
              <SpacedRow alignItems="center" key={timeTypeName}>
                <TimeType.Title>
                  <TextOverflowWithPopover maxWidth="90px">
                    {timeTypeName}
                  </TextOverflowWithPopover>
                  :
                </TimeType.Title>

                <Flex alignItems="center" gap={1}>
                  <Color color={color} />
                  <TimeType.Value>
                    {Utils.DateTime.formatDuration(seconds)}
                  </TimeType.Value>
                </Flex>
              </SpacedRow>
            ))}
        </TimeType.Container>
      )}
    </SpacedColumn>
  )
}
