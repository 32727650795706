import { TimeoffsByCursorNode } from 'API/Timeoffs/GraphQL'
import { DateTime } from 'luxon'

function formatTime(isoTimestamp: string, timezone?: string): string {
  let datetime = DateTime.fromISO(isoTimestamp)
  if (timezone) datetime = datetime.setZone(timezone)
  return datetime.toLocaleString(DateTime.TIME_SIMPLE)
}

export function getTimeoffStartEnd(
  timeoff: TimeoffsByCursorNode,
  timezone: string,
): string {
  if (timeoff.partial)
    return `${formatTime(timeoff.startAt, timezone)} - ${formatTime(
      timeoff.endAt,
      timezone,
    )}`

  const day = DateTime.local()
  return `${formatTime(day.startOf('day').toISO())} - ${formatTime(
    day.endOf('day').toISO(),
  )}`
}

export function convertHoursToHoursMinutes(hours: number): string {
  const wholeHours = Math.floor(hours)
  const minutes = Math.round((hours - wholeHours) * 60)
  return `${wholeHours}:${minutes}`
}
