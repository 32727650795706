import React from 'react'
import { components, OptionProps } from 'react-select'

import { SelectOptionWithColor } from 'Types/common'

import { ShiftTagItem } from './ShiftTagItem'
import { ShiftTagItemContainer } from './styles'

export function ShiftTagOption({
  data,
  ...rest
}: OptionProps<SelectOptionWithColor<number>, true>) {
  return (
    <components.Option data={data} {...rest}>
      <ShiftTagItemContainer>
        <ShiftTagItem color={data.color} label={data.label} />
      </ShiftTagItemContainer>
    </components.Option>
  )
}
