import React from 'react'
import { Control } from 'react-hook-form'

import { SelectOption } from 'Types/common'

import { InfoPopover } from 'components/blocks/__v2__'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { FormSelect, FormSwitch } from 'components/ui/__v3__/HookForm'

import i18n from 'i18n'

import { HOURS_OPTIONS } from '../constants'
import { GlobalSettingsFormState } from '../types'

interface Props {
  control: Control<GlobalSettingsFormState, any>
  isEarlyClockInEnabled: boolean
  minutesOptions: SelectOption<number>[]
}

export function FormEarlyClockIn({
  control,
  isEarlyClockInEnabled,
  minutesOptions,
}: Props) {
  return (
    <FormSwitch<GlobalSettingsFormState>
      control={control}
      label={
        <Span mt="2px">
          <Span inline mr={1}>
            {i18n('settings.earlyClockIn.descriptionFirstPart')}
          </Span>

          <Span width={46}>
            <FormSelect<GlobalSettingsFormState>
              control={control}
              isClearable={false}
              isDisabled={!isEarlyClockInEnabled}
              name="earlyClockInLimitHours"
              options={HOURS_OPTIONS}
              returnOptionObject={false}
              withPortal
            />
          </Span>

          <Span inline ml={1} mr={1}>
            {i18n('settings.earlyClockIn.hours')}
          </Span>

          <Span width={46}>
            <FormSelect<GlobalSettingsFormState>
              control={control}
              isClearable={false}
              isDisabled={!isEarlyClockInEnabled}
              name="earlyClockInLimitMinutes"
              options={minutesOptions}
              returnOptionObject={false}
              withPortal
            />
          </Span>

          <Span inline ml={1}>
            {i18n('settings.earlyClockIn.minutes')}
          </Span>

          <Flex>
            <Span inline>
              {i18n('settings.earlyClockIn.descriptionSecondPart')}
            </Span>

            <Flex ml={2} mt="3px">
              <InfoPopover
                iconSize={undefined}
                maxWidth={200}
                placement="bottom"
                popoverContent={i18n(
                  'settings.earlyClockIn.defaultCanViewInfo',
                )}
              />
            </Flex>
          </Flex>
        </Span>
      }
      name="earlyClockIn"
      withInputBlock
    />
  )
}
