import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { TextAreaPopover } from 'components/ui/__v3__'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'

type Props = {
  labelText?: string
  required?: boolean
  disabled?: boolean
  width?: number
  minWidth?: number
  placeholder?: string
}

export function FormTextAreaPopover<T extends FieldValues>({
  control,
  disabled,
  name,
  required = false,
  ...rest
}: ControllerBaseProps<T> & Props) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const renderErrorIfExists = () => {
    if (!error) return null
    return <Error error={error} />
  }

  return (
    <TextAreaPopover
      disabled={disabled}
      renderErrorIfExists={renderErrorIfExists}
      required={required}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  )
}
