import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'

import { getRequiredProfileFields } from 'config'
import { createStructuredSelector } from 'reselect'
import { Company, Viewer } from 'Types/app'

import { Plan } from 'constants/products'

import { useLocale } from 'hooks/Locale/useLocale'
import { useAuth } from 'hooks/useAuth'

import { dateLocales } from 'i18n'

import { getIsRefetched } from 'store/selectors/app'
import { getCompany, getProducts, getViewer } from 'store/selectors/viewer'

import { AppContext } from './AppContext'
import { prepareAppContextCompany, prepareAppContextViewer } from './helpers'
import { useCompany, useDefaultLocation, useViewer } from './hooks'

const appContextSelector = createStructuredSelector<
  unknown,
  {
    company: Company
    reduxViewer: Viewer
    products: Plan[]
    isRefetched: boolean
  }
>({
  isRefetched: getIsRefetched,
  company: getCompany,
  reduxViewer: getViewer,
  products: getProducts,
})

interface AppContextProviderProps {}
export function AppContextProvider({
  children,
}: PropsWithChildren<AppContextProviderProps>) {
  const { isAuthenticated, isLoading } = useAuth()
  const locale = useLocale()
  const { company, reduxViewer, products, isRefetched } = useSelector(
    appContextSelector,
  )

  const viewer = prepareAppContextViewer(reduxViewer)

  // Load additional data
  const { me, meLoading } = useViewer(isAuthenticated)
  const { company: gatewayCompany, companyLoading } = useCompany(
    isAuthenticated,
  )
  // TODO: handle case for owner with no locations
  const { defaultLocation, defaultLocationLoading } = useDefaultLocation(
    viewer.role.currentlyEmployee,
    company.id,
  )

  const preparedCompany = prepareAppContextCompany({
    coreCompany: company,
    microserviceCompany: gatewayCompany,
    products,
  })

  const requiredProfileFields = getRequiredProfileFields(company.identity)

  const isNahdi = preparedCompany.identity.Nahdi
  const isEtfo = preparedCompany.identity.Etfo
  const isSobeys = preparedCompany.identity.Sobeys
  const isDemo = preparedCompany.identity.Demo
  const isDeluxe = preparedCompany.identity.Deluxe
  const isKeywords = preparedCompany.identity.Keywords
  const isHrwize = preparedCompany.identity.Hrwize
  const isAldo = preparedCompany.identity.Aldo
  const isGarda = preparedCompany.identity.Garda
  const isFps = preparedCompany.identity.Fps

  // Company global settings
  const isShowingWagesToManagers = Boolean(
    company?.settings?.showWagesToManagers,
  )
  const canViewWage = !(
    (viewer.role.currentlyManager && !isShowingWagesToManagers) ||
    isEtfo ||
    isAldo
  )

  // NOTE: we'll enable it later for the rest of the companies
  const canViewShiftSwapPermissions = isGarda

  const loading =
    isLoading ||
    (viewer.role.currentlyManager &&
      (defaultLocationLoading || !defaultLocation)) ||
    defaultLocationLoading ||
    (isAuthenticated && !isRefetched) ||
    (!me && meLoading) ||
    (!gatewayCompany && companyLoading)

  return (
    <AppContext.Provider
      value={{
        authenticated: isAuthenticated,
        loading,

        dateLocale: dateLocales[locale],

        products,
        viewer,
        me,

        company: preparedCompany,
        canViewWage,
        canViewShiftSwapPermissions,

        requiredProfileFields,

        // ===========================================================
        // Following properties are deprecated and should be removed
        // ===========================================================
        companyId: company?.id,
        companySettings: company.settings,
        microserviceCompanyId: gatewayCompany?.id,

        defaultLocation,

        // We need to replace this later on with a proper access level
        isNahdi,
        isEtfo,
        isSobeys,
        isDemo,
        isDeluxe,
        isKeywords,
        isHrwize,
        isAldo,
        isGarda,
        isFps,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
