import React from 'react'

import { flexRender } from '@tanstack/react-table'

import isEmpty from 'lodash/isEmpty'

import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import { EmptyData, TableLoadingContainer, Td, Tr } from './styles'
import { DataTableProps } from './types'

export function TableBody<T>({ table, loading }: DataTableProps<T>) {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <TableLoadingContainer>
              <Spinner primary size={42} />
            </TableLoadingContainer>
          </td>
        </tr>
      </tbody>
    )
  }

  if (isEmpty(table.options.data)) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <EmptyData>{i18n('common.tableNoData')}</EmptyData>
          </td>
        </tr>
      </tbody>
    )
  }

  const { rows } = table.getRowModel()

  return (
    <tbody>
      {rows.map((row, index) => {
        const firstExpanded = row.getIsExpanded()
        const expanded = firstExpanded || !!row.depth
        const nextExpanded = rows[index + 1]?.getIsExpanded()
        const lastExpanded = expanded && !rows[index + 1]?.depth

        const borderBottomHighlighted = nextExpanded || lastExpanded
        const borderTopHighlighted = firstExpanded

        return (
          <Tr
            borderBottomHighlighted={borderBottomHighlighted}
            borderTopHighlighted={borderTopHighlighted}
            expanded={expanded}
            key={row.id}
          >
            {row.getVisibleCells().map(cell => (
              // @ts-ignore
              <Td
                key={cell.id}
                {...table.options.meta}
                {...cell.column.columnDef.meta}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        )
      })}
    </tbody>
  )
}
