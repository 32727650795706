import { AiOutlinePlusCircle } from 'react-icons/ai'

import styled, { css } from 'styled-components'
import { width, WidthProps } from 'styled-system'

import { Span } from 'components/ui/__v2__/Grid'
import { BaseButton } from 'components/ui/__v3__/Button/BaseButton'

import { TimesheetState } from './types'

const paddingCss = css`
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
`

export const Days = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
  ${paddingCss}
`

export const Day = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.space[3]}px 0;
    gap: ${({ theme }) => theme.space[2]}px;
  `,
  Header: styled.div<{ today?: boolean }>`
    display: flex;
    gap: ${({ theme }) => theme.space[2]}px;
    align-items: center;

    ${({ today }) =>
      today &&
      css`
        border-bottom: 2px solid ${({ theme }) => theme.colors.BURGUNDY};
      `}
  `,
  Title: styled(Span).attrs({ as: 'h3' })<{ today?: boolean }>`
    ${({ today }) =>
      today &&
      css`
        color: ${({ theme }) => theme.colors.BURGUNDY};
      `}
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${({ theme }) => theme.space[2]}px;
    gap: ${({ theme }) => theme.space[2]}px;
  `,
}

export const Holiday = {
  Container: styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    gap: ${({ theme }) => theme.space[1]}px;
    background-color: ${({ theme }) => theme.colors.CAROUSEL_PINK};
  `,
  Title: styled(Span)`
    color: ${({ theme }) => theme.colors.BURGUNDY};

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  `,
}

export const NoRecords = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: ${({ theme }) => theme.space[2]}px;
  color: ${({ theme }) => theme.layout.darkBorder};
  height: 38px;
`

export const Layout = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 240px;
  grid-gap: ${({ theme }) => theme.space[2]}px;
  width: 100%;
`

export const AddIcon = styled(AiOutlinePlusCircle)`
  color: ${({ theme }) => theme.layout.primary};
`

export const IconButton = styled(BaseButton)`
  background: none;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    & > svg {
      color: ${({ theme }) => theme.layout.disabled} !important;
    }
  }
`

export const RightPane = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]}px;
  gap: ${({ theme }) => theme.space[2]}px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  ${paddingCss}
`

export const SummaryHeader = styled.div`
  display: flex;
  flex-direction: column;
`

type StatusProps = {
  status: TimesheetState
}

export const stateColorCss = ({ status }: StatusProps) => {
  return css`
    ${({ theme }) => {
      if (status === TimesheetState.Unsubmitted) {
        return theme.layout.darkBorder
      }

      if (status === TimesheetState.Pending) {
        return theme.toasts.secondaryColor.info
      }

      if (status === TimesheetState.Approved) {
        return theme.toasts.secondaryColor.success
      }

      if (status === TimesheetState.Discarded) {
        return theme.toasts.secondaryColor.error
      }

      return theme.layout.darkBorder
    }}
  `
}

export const TimesheetStatus = styled.span<StatusProps>`
  padding: ${({ theme }) => theme.space[2]}px;
  line-height: 14px;
  text-align: center;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.space[1]}px;
  width: 100%;
  color: ${stateColorCss};
  border-color: ${stateColorCss};
`

// New button from updated design
export const SidebarBaseButton = styled.button<WidthProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 0;

  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.WHISPER_GRAY};
  background: ${({ theme }) => theme.colors.WHITE};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.SILVER_GRAY};
    background: ${({ theme }) => theme.colors.LILAC_GRAY};
  }

  &:active {
    background: ${({ theme }) => theme.colors.SPUN_PEARL};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.NOBEL};
    background: ${({ theme }) => theme.colors.SEASHELL};
  }

  ${width};
`

export const SimulationButton = styled(SidebarBaseButton)``

export const SimulationButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.space[2]}px;
  gap: ${({ theme }) => theme.space[2]}px;
  border-radius: ${({ theme }) => theme.space[1]}px;
  background: ${({ theme }) => theme.colors.HAWKES_BLUE};
  color: ${({ theme }) => theme.colors.COOL_GRAY};
`
