/* eslint-disable no-console */
declare const self: SharedWorkerGlobalScope

export default () => {
  // Note: This is a shared worker, so we need to define the message types here
  // or they will be undefined in the worker scope
  enum WorkerMessageType {
    START = 'START',
    TIMEOUT = 'TIMEOUT',
    UPDATE = 'UPDATE',
    DISCONNECT = 'DISCONNECT',
  }
  type WorkerMessage = {
    type: WorkerMessageType
    payload?: {
      duration?: number
    }
  }

  const ports: Set<MessagePort> = new Set()
  const CHECK_INTERVAL = 1000 // 1 second
  let sessionDuration = 0
  let checkIntervalId: NodeJS.Timeout | null = null

  const broadcastMessage = (message: WorkerMessage) => {
    ports.forEach(port => port.postMessage(message))
  }

  const cleanup = (port: MessagePort) => {
    ports.delete(port)
    if (ports.size === 0 && checkIntervalId) {
      // Only clears interval if ALL ports are gone
      clearInterval(checkIntervalId)
      checkIntervalId = null
    }
  }

  const startSessionCheck = () => {
    if (checkIntervalId) {
      clearInterval(checkIntervalId)
    }

    const expiresAt = Date.now() + sessionDuration * 1000

    checkIntervalId = setInterval(() => {
      if (Date.now() >= expiresAt) {
        broadcastMessage({
          type: WorkerMessageType.TIMEOUT,
        })
      }
    }, CHECK_INTERVAL)
  }

  self.onconnect = (event: MessageEvent) => {
    const port = event.ports[0] // This port is specific to this connection/tab
    ports.add(port)
    port.start()

    // These handlers are bound to THIS SPECIFIC port through closure
    // When the tab closes, these handlers still have access to the correct port
    port.onmessageerror = () => cleanup(port) // This 'port' is from the closure
    port.addEventListener('close', () => cleanup(port))

    port.onmessage = (message: MessageEvent<WorkerMessage>) => {
      const { type, payload } = message.data

      switch (type) {
        case WorkerMessageType.START:
        case WorkerMessageType.UPDATE:
          sessionDuration = payload?.duration ?? 0
          startSessionCheck()
          break

        case WorkerMessageType.DISCONNECT:
          cleanup(port)
          break

        default:
          break
      }
    }
  }
}
