import { useAcceptShiftTrade } from './useAcceptShiftTrade'
import { useCreateShiftTrade } from './useCreateShiftTrade'
import { useRejectShiftTradeSchedule } from './useRejectShiftTradeSchedule'
import { useShiftTradesByCursor } from './useShiftTradesByCursor'

export const ShiftTradesAPI = {
  byCursor: useShiftTradesByCursor,

  accept: useAcceptShiftTrade,
  reject: useRejectShiftTradeSchedule,
  create: useCreateShiftTrade,
}
