export enum ScheduleStartsWithinFilter {
  Half = 0.5,
  One = 1,
  OneAndHalf = 1.5,
  Two = 2,
}

export enum WorkStatusFilter {
  All = 'all',
  OnBreak = 'onBreak',
  NotOnBreak = 'notOnBreak',
}
