import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DayModifiers, Modifier } from 'react-day-picker'

import { FiX } from 'react-icons/fi'

import styled from 'styled-components'
import { omit, pick } from '@styled-system/props'

import { DateTime } from 'luxon'

import { pick as pickLodash } from 'lodash'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import Popover from 'components/ui/__v2__/Popover/Popover'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'

import { DAY_NAMES_FROM_SUN, MONTH_NAMES } from 'constants/ids'

import { useAppContext } from 'hooks'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import { DAY_VIEW_FORMAT } from './config'
import {
  calculateIntervalFromMaxSelectionLength,
  getValidAfterDate,
  getValidBeforeDate,
  mergeDisabledDaysWithInterval,
  selectingFirstDay,
  stateToResult,
} from './helpers'
import { PickerInput } from './PickerInput'
import {
  CalendarIcon,
  ClearButton,
  Container,
  Picker,
  PickerButton,
  PickerButtons,
  PickerInputBoxWrapper,
  Placeholder,
  Toggle,
} from './styles'
import { DayPickerExternalProps, DayPickerState } from './types'

import { Menu } from '../Menu'

const initialState: DayPickerState = {
  from: null,
  to: null,
  hovered: null,
  isToDayValid: false,
  isFromDayValid: false,
}

function DayPicker({
  autoWidth = false,
  dayOnly = false,
  disabled = false,
  disabledDays = undefined,
  disableFuture = false,
  disablePast = false,
  errored = false,
  format = null,
  from = null,
  inputFormat = 'yyyy-MM-dd',
  maxSelectionLength = null,
  placement = 'auto',
  placeholder = 'YYYY-MM-DD',
  workweekStartDay,
  to = null,
  withInput = false,
  withPortal = true,
  labelText = undefined,
  required = false,
  isClearable,
  showTooltip,
  onChange,
  onBlur = noop,
  onReset,
  ...rest
}: DayPickerExternalProps) {
  const { company } = useAppContext()

  const firstDayOfWeek = Utils.DateTime.mapToRRuleWeekDay(
    isNumber(workweekStartDay)
      ? workweekStartDay
      : company.settings.calendarStartDay,
  )

  const [visible, setVisible] = useState(false)

  const [state, setState] = useState<DayPickerState>({
    from: from ? DateTime.fromJSDate(from) : null,
    to: to ? DateTime.fromJSDate(to) : null,
    hovered: to ? DateTime.fromJSDate(to) : null,
    isToDayValid: false,
    isFromDayValid: false,
  })

  const prevState = useRef<DayPickerState>({ ...state })

  useEffect(() => {
    const nextState = {
      from: from ? DateTime.fromJSDate(from) : null,
      to: to ? DateTime.fromJSDate(to) : null,
      hovered: to ? DateTime.fromJSDate(to) : null,
      isToDayValid: false,
      isFromDayValid: false,
    }
    prevState.current = nextState
    setState(nextState)
  }, [from, to])

  const toggleVisibility = () => !disabled && setVisible(!visible)

  const handleShowCalendar = () => setVisible(true)

  const handleHideCalendar = () => setVisible(false)

  const handleReset = useCallback(() => setState({ ...initialState }), [])

  const handleChange = useCallback(
    (nextState: DayPickerState = state) => {
      prevState.current = { ...nextState }

      const changed = stateToResult(nextState, dayOnly)

      // @ts-ignore Note: Some weird compiler behaviour here, discrimination works as intended
      onChange(changed, nextState)
    },
    [dayOnly, onChange, state],
  )

  const handleDayChange = useCallback(
    (
      day: Date | undefined,
      modifiers: DayModifiers = { today: undefined, outside: undefined },
      dayPickerInput: $TSFixMe, // Note: needs to support both click and keyboard input
    ) => {
      // value typed from keyboard
      const typedInputValue = dayPickerInput?.input?.inputElement?.value as
        | string
        | undefined

      if (modifiers.disabled) {
        return
      }

      // the ability to remove the effective period and leave it empty
      if (!day && !typedInputValue && withInput) {
        const nextState = {
          ...state,
          from: null,
          hovered: null,
          // Note: empty date is still valid date
          isFromDayValid: !!(
            (!typedInputValue && day) ||
            (typedInputValue && day) ||
            typedInputValue === ''
          ),
        }

        setState(nextState)
        handleChange(nextState)

        return
      }

      // the ability to change the date state and
      // display info about the wrong date (if an error is displayed)
      if (!day) {
        const nextState = {
          ...state,
          from: null,
          to: null,
          isToDayValid: false,
          isFromDayValid: false,
        }

        setState(nextState)
        if (withInput) {
          handleChange(nextState)
        }

        return
      }

      const selectedDateTime = DateTime.fromJSDate(day)

      const { from: localFrom, to: localTo } = state

      if (
        localFrom &&
        localTo &&
        selectedDateTime >= localFrom &&
        selectedDateTime <= localTo
      ) {
        handleReset()
      } else if (dayOnly || selectingFirstDay(localFrom, localTo, day)) {
        const nextState = {
          ...state,
          from: DateTime.fromJSDate(day),
          to: null,
          hovered: null,
          // Note: empty date is still valid date
          isFromDayValid: !!(
            (!typedInputValue && day) ||
            (typedInputValue && day) ||
            typedInputValue === ''
          ),
        }

        setState(nextState)

        if (withInput) {
          handleChange(nextState)
        }
      } else {
        setState(ps => ({
          ...ps,
          to: selectedDateTime,
          hovered: selectedDateTime,
          // Note: empty date is still valid date
          isToDayValid: !!(
            (!typedInputValue && selectedDateTime) ||
            (typedInputValue && selectedDateTime) ||
            typedInputValue === ''
          ),
        }))
      }
    },
    [state, dayOnly, handleReset, withInput, handleChange],
  )

  const handleDayClickDecorator = (doOnClick: Function) => (
    day: Date,
    modifiers: DayModifiers = { today: undefined, outside: undefined },
    event: $TSFixMe, // Note: needs to support both click and keyboard input
  ) => {
    if (withInput) {
      handleHideCalendar()
    }

    doOnClick(day, modifiers, event)
  }

  const handleDayMouseEnter = (day: Date) => {
    const { from: localFrom, to: localTo } = state
    if (!selectingFirstDay(localFrom, localTo, day)) {
      setState({
        ...state,
        hovered: DateTime.fromJSDate(day),
      })
    }
  }

  const handleCancel = () => {
    setState({ ...prevState.current })
    toggleVisibility()
  }

  const handleOk = () => {
    toggleVisibility()
    handleChange()
  }

  const translations = useMemo(() => {
    const months = MONTH_NAMES.slice(0, MONTH_NAMES.length)
    const translatedMonth = map(months, name => i18n(name))
    const longs = map(DAY_NAMES_FROM_SUN, day => i18n(day))
    const shorts = map(longs, day => day.slice(0, 2))
    return {
      months: translatedMonth,
      longs,
      shorts,
    }
  }, [])

  const pickerDisabledDays = useMemo(() => {
    let interval: { after?: Date; before?: Date } = {}

    if (maxSelectionLength) {
      const { from: localFrom } = state
      interval = calculateIntervalFromMaxSelectionLength(
        maxSelectionLength,
        localFrom,
      )
    }

    if (disableFuture) {
      interval = { ...interval, ...getValidAfterDate(disableFuture) }
    }

    if (disablePast) {
      interval = { ...interval, ...getValidBeforeDate(disablePast) }
    }

    if (disabledDays) {
      return mergeDisabledDaysWithInterval(disabledDays as Modifier[], interval)
    }

    return interval
  }, [disableFuture, disablePast, disabledDays, maxSelectionLength, state])

  const formattedFromDate =
    state.from && state.from.toLocaleString(format ?? DAY_VIEW_FORMAT)

  const formattedToDate =
    state.to && state.to.toLocaleString(format ?? DAY_VIEW_FORMAT)

  const selectedDays = dayOnly
    ? state.from?.toJSDate()
    : [
        state.from?.toJSDate(),
        {
          from: state.from?.toJSDate(),
          to: state.hovered?.toJSDate(),
        },
      ]

  const modifiers = {
    start: state.from?.toJSDate(),
    end: state.hovered?.toJSDate(),
  }

  const handleClear = () => {
    handleChange({
      ...state,
      from: null,
      to: null,
      isToDayValid: false,
      isFromDayValid: false,
    })
  }

  return (
    <Flex flexDirection="column">
      <Container autoWidth={autoWidth} width="165px" {...pick(rest)}>
        <Menu
          borderWidth={0}
          content={
            <Menu.Content
              minWidth={350}
              pb={dayOnly && withInput ? 12 : 24}
              pt={dayOnly && withInput ? 12 : 16}
              px={24}
            >
              <Picker
                dayOnly={dayOnly}
                // @ts-ignore FIXME:
                disabledDays={pickerDisabledDays}
                firstDayOfWeek={firstDayOfWeek}
                modifiers={modifiers}
                month={state.from?.toJSDate() ?? undefined}
                months={translations.months}
                selectedDays={selectedDays}
                weekdaysLong={translations.longs}
                weekdaysShort={translations.shorts}
                onDayClick={handleDayClickDecorator(handleDayChange)}
                onDayMouseEnter={handleDayMouseEnter}
              />
              {!(dayOnly && withInput) && (
                <PickerButtons>
                  {/* @ts-ignore */}
                  <PickerButton secondary onClick={handleCancel}>
                    {i18n('actions.cancel')}
                  </PickerButton>
                  <PickerButton
                    disabled={dayOnly ? !state.from : !state.from || !state.to}
                    onClick={handleOk}
                  >
                    {i18n('actions.calendarOk')}
                  </PickerButton>
                </PickerButtons>
              )}
            </Menu.Content>
          }
          placement={placement}
          visible={visible}
          withPortal={withPortal}
          onClickOutside={handleCancel}
          onHide={onBlur}
        >
          {dayOnly && withInput ? (
            <InputLabel position="relative" width="100%">
              <LabelText labelText={labelText} required={required} />

              <PickerInputBoxWrapper disabled={disabled}>
                <PickerInput
                  dayPickerProps={{
                    disabledDays,
                    firstDayOfWeek,
                    modifiers,
                  }}
                  format={inputFormat}
                  inputProps={{
                    disabled,
                    errored,
                    placeholder,
                    required,
                  }}
                  overlayComponent={() => null}
                  value={state.from?.toJSDate()}
                  onDayChange={handleDayChange}
                  onDayPickerShow={handleShowCalendar}
                  {...pickLodash(rest, ['content'])}
                />
              </PickerInputBoxWrapper>
            </InputLabel>
          ) : (
            <InputLabel position="relative" width="100%">
              <LabelText labelText={labelText} required={required} />

              <Toggle
                disabled={disabled}
                errored={errored}
                tabIndex={-1}
                onClick={toggleVisibility}
                {...omit(rest)}
              >
                {/* @ts-ignore */}
                <Popover
                  content={<Flex>{i18n('common.goBackToCrrentDay')}</Flex>}
                  disabled={!showTooltip}
                >
                  <Flex>
                    <CalendarIcon
                      onClick={event => {
                        if (!showTooltip) return

                        event.stopPropagation()
                        onReset?.()
                      }}
                    />
                  </Flex>
                </Popover>

                {dayOnly && !state.from && (
                  <Placeholder>
                    {placeholder ?? i18n('availability.pleaseSelectDate')}
                  </Placeholder>
                )}
                {dayOnly && state.from && <Span>{formattedFromDate}</Span>}
                {!dayOnly && !state.from && !state.to && (
                  <Placeholder>
                    {placeholder ?? i18n('actions.pleaseSelectFirstDay')}
                  </Placeholder>
                )}
                {!dayOnly && state.from && !state.to && (
                  <Placeholder>
                    {i18n('actions.pleaseSelectSecondDay')}
                  </Placeholder>
                )}
                {!dayOnly && !!state.from && !!state.to && (
                  <Span>{`${formattedFromDate} – ${formattedToDate}`}</Span>
                )}
              </Toggle>
              {isClearable && state.from && state.to && (
                <ClearButton onClick={handleClear}>
                  <FiX size={16} />
                </ClearButton>
              )}
            </InputLabel>
          )}
        </Menu>
      </Container>
    </Flex>
  )
}

export const SlimDayPicker = styled(DayPicker).attrs({})`
  display: flex;
  align-items: center;
  height: 26px;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  width: 100%;
`

const StyledDayPicker = styled(DayPicker).attrs({})`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 34px;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
`
export default StyledDayPicker
