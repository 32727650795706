import React, { ReactNode, useState } from 'react'

import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import { PopoverContainer, PopoverContainerProps } from './styles'

type PopoverProps = {
  children: ReactNode
  content: ReactNode
  trigger?: 'click' | 'hover'
  placement?: 'top' | 'bottom' | 'left' | 'right'
  offsetDistance?: number
  interactive?: boolean
  withPortal?: boolean
} & PopoverContainerProps

export function Popover({
  children,
  content,
  trigger = 'click',
  placement = 'bottom',
  offsetDistance = 8,
  interactive = false,
  withPortal = false,
  ...containerProps
}: PopoverProps) {
  const [open, onOpenChange] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    middleware: [
      offset(offsetDistance),
      autoPlacement({
        allowedPlacements: placement ? [placement] : undefined,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context, {
    enabled: trigger === 'click',
  })

  const hover = useHover(context, {
    enabled: trigger === 'hover',
    handleClose: interactive ? safePolygon() : undefined,
  })

  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    hover,
    dismiss,
    role,
  ])

  const Portal = withPortal ? FloatingPortal : React.Fragment

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>

      {open && (
        <Portal>
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            {...containerProps}
          >
            {content}
          </PopoverContainer>
        </Portal>
      )}
    </>
  )
}
