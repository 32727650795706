import { useAcceptShiftCover } from './useAcceptShiftCover'
import { useCreateShiftCover } from './useCreateShiftCover'
import { useRejectShiftCover } from './useRejectShiftCover'
import { useShiftCoversByCursor } from './useShiftCoversByCursor'

export const ShiftCoversAPI = {
  byCursor: useShiftCoversByCursor,

  accept: useAcceptShiftCover,
  reject: useRejectShiftCover,
  create: useCreateShiftCover,
}
