import React, { useMemo } from 'react'

import { LuCalendarOff } from 'react-icons/lu'

import { TimeoffsByCursorNode } from 'API/Timeoffs/GraphQL'

import { Flex } from 'components/ui/__v2__/Grid'
import { TextAreaPopover } from 'components/ui/__v3__'
import { TimetypeBadge } from 'components/ui/__v3__/Badge/TimetypeBadge'

import { useI18n, useLocale } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import { getTimeoffStartEnd } from './helpers'
import { ApprovedBadge, PaidBadge, Section, TimeoffBadge } from './styles'

import { CheckIcon, Timesheet } from '../styles'

type TimeoffRecordProps = {
  timeoff: TimeoffsByCursorNode
  timezone: string
}

export function TimeoffRecord({ timeoff, timezone }: TimeoffRecordProps) {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.timeoff>(
    'weeklyTimesheets.timeoff',
  )
  const locale = useLocale()

  const timezoneCode = timezone
    ? Utils.DateTime.zoneOffsetName(timezone, locale)
    : ''

  const timeoffRenderData = useMemo(
    () =>
      [
        [
          <Flex alignItems="center" flexDirection="row" gap={1}>
            {t('labels.leaveName')}
            {timeoff.customLeaveDay?.paid && (
              <PaidBadge>{t('phrases.paid')}</PaidBadge>
            )}
          </Flex>,
          timeoff.customLeaveDay?.name,
        ],
        [
          `${t('labels.startEnd')} (${timezoneCode})`,
          getTimeoffStartEnd(timeoff, timezone),
        ],
        [
          t('labels.amount'),
          `${Utils.DateTime.formatDuration(
            Utils.DateTime.hoursToSeconds(timeoff.quantity.hours),
          )} (${timeoff.quantity.days.toFixed(2)}${i18n(
            'dayHourEquivalence.shorts.day',
          )})`,
        ],
        [
          t('labels.timeType'),
          timeoff.customLeaveDay?.defaultTimeType ? (
            <TimetypeBadge
              color={timeoff.customLeaveDay.defaultTimeType.color}
              label={timeoff.customLeaveDay.defaultTimeType.name}
              maxWidth="90px"
            />
          ) : (
            '—'
          ),
        ],

        [
          t('labels.durationType'),
          t(timeoff.partial ? 'phrases.partial' : 'phrases.wholeDay'),
        ],
        [
          t('labels.comments'),
          <TextAreaPopover disabled value={timeoff.reason} />,
        ],
      ] as const,
    [t, timeoff, timezone, timezoneCode],
  )

  return (
    <Timesheet.Container>
      <Timesheet.Timeoff>
        {timeoffRenderData.map(([header, content]) => (
          <Section.Container key={header.toString()}>
            <Section.Header>{header}</Section.Header>
            <Section.Content>{content}</Section.Content>
          </Section.Container>
        ))}
      </Timesheet.Timeoff>

      <Timesheet.Footer>
        <ApprovedBadge>
          {t('phrases.approved')} <CheckIcon />
        </ApprovedBadge>

        <TimeoffBadge>
          <LuCalendarOff /> {t('phrases.timeoff')}
        </TimeoffBadge>
      </Timesheet.Footer>
    </Timesheet.Container>
  )
}
