import React from 'react'

import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import { IconContainer } from './styles'

interface Props {
  expanded: boolean
  onToggleClick: (event: unknown) => void
}
export function Expand({ expanded, onToggleClick }: Props) {
  return (
    <IconContainer onClick={onToggleClick}>
      {expanded ? <FiChevronUp /> : <FiChevronDown />}
    </IconContainer>
  )
}
