import styled from 'styled-components'

export const PaidBadge = styled.div`
  width: 32px;
  height: 18px;
  padding: 2px 4px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.OUTLINE_BASE};
  background: ${({ theme }) => theme.layout.bg};
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

export const Section = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[1]}px;
  `,
  Header: styled.span`
    color: ${({ theme }) => theme.colors.COOL_GRAY};
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  `,
  Content: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.space[2]}px 0;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  `,
}

export const ApprovedBadge = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]}px;

  color: ${({ theme }) => theme.toasts.secondaryColor.success};
`

export const TimeoffBadge = styled.div`
  display: flex;
  align-items: center;

  width: 88px;
  height: 20px;
  padding: 2px 8px;
  gap: ${({ theme }) => theme.space[1]}px;
  border-radius: 4px;

  background: ${({ theme }) => theme.colors.CAROUSEL_PINK};
  color: ${({ theme }) => theme.colors.BURGUNDY};
`
