import React, { useMemo, useState } from 'react'

import { API } from 'API'
import { SimulatedClassifiedTimeFields } from 'API/ClassifiedTime/GraphQL/Fragments/types'

import { Flex } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v3__'

import { useI18n, useMount } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import { Info, SimulationData } from './components'
import { processSimulationData, processSimulationTotalData } from './helpers'
import { Button, Content, Description } from './styles'

import { formItemToSimulationAdditionalTimeEntryPayload } from '../../helpers'
import { FormTimesheetRecord } from '../../types'

type Props = {
  employeeTimezone: string
  period: {
    start: string
    end: string
  }
  employeeId: number
  employeeName: string
  timeEntries: FormTimesheetRecord[]
  onClose: () => void
}
export function TimeClassificationSimulationModal({
  employeeTimezone,
  timeEntries,
  period,
  employeeId,
  employeeName,
  onClose,
}: Props) {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.simulationModal>(
    'weeklyTimesheets.simulationModal',
  )

  const [simulationResults, setSimulationResults] = useState<
    SimulatedClassifiedTimeFields[]
  >([])

  const {
    simulateTimeClassification,
    simulating,
  } = API.ClassifiedTime.simulate()

  useMount(() => {
    const input = {
      employeeId: employeeId.toString(),
      period,
      additionalTimeEntries: timeEntries.map(
        formItemToSimulationAdditionalTimeEntryPayload,
      ),
      includePending: true,
    }
    simulateTimeClassification(input).then(setSimulationResults)
  })

  const { rowsData, totalData } = useMemo(() => {
    const rowsData = processSimulationData(simulationResults, employeeTimezone)
    const totalData = processSimulationTotalData(
      simulationResults,
      employeeTimezone,
    )
    return { rowsData, totalData }
  }, [simulationResults, employeeTimezone])

  return (
    <Modal.Container isOpen padding={24}>
      <Modal.Close onClose={onClose} />
      <Modal.Title fontSize={'16px'}>{t('title')}</Modal.Title>

      <Content>
        <Description mb={0}>{t('description')[0]}</Description>
        <Description>{t('description')[1]}</Description>
        <Info employeeName={employeeName} period={period} />
        <Flex mt={5}>
          <SimulationData
            data={rowsData}
            isLoading={simulating}
            totalData={totalData}
          />
        </Flex>
      </Content>

      <Modal.Actions gap={2} justifyContent="center" mt={4}>
        <Button secondary onClick={onClose}>
          {i18n('actions.close')}
        </Button>
      </Modal.Actions>
    </Modal.Container>
  )
}
