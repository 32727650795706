import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

export type PopoverContainerProps = LayoutProps & SpaceProps

export const PopoverContainer = styled.div<PopoverContainerProps>`
  background: ${({ theme }) => theme.layout.bg};
  border-radius: ${({ theme }) => theme.space[2]}px;
  box-shadow: ${({ theme }) => theme.tooltip.shadow};
  border-color: ${({ theme }) => theme.layout.border};
  padding: ${({ theme }) => theme.space[2]}px;
  border-style: solid;
  z-index: ${({ theme }) => theme.zIndices.popup};

  ${space}
  ${layout}
`
