import React, { useCallback, useMemo, useState } from 'react'

import { ShiftTagsAPI } from 'API'
import { SelectOptionWithColor } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import { PAGE_SIZE } from 'constants/pagination'

import { i18n } from 'i18n'

import {
  ShiftTagItem,
  ShiftTagMultiValue,
  ShiftTagOption,
  ValueContainer,
} from './components'
import { shiftTagToOption } from './mappers'
import { ShiftTagsPickerProps } from './types'

export function ShiftTagsPickerV2({
  onSelect,
  value,
  isMulti,
  isClearable = true,
  withPortal = true,
  ...rest
}: ShiftTagsPickerProps) {
  const [input, setInput] = useState('')

  const { shiftTags, fetching } = ShiftTagsAPI.getByOffset({
    paging: { limit: PAGE_SIZE[10], offset: 0 },
    filter: input ? { name: { ilike: input } } : {},
  })

  const defaultOptions = useMemo(() => shiftTags.map(shiftTagToOption), [
    shiftTags,
  ])

  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action === 'input-change' || action.action === 'set-value')
      setInput(inputValue)
  }

  const handleChange = isMulti
    ? (value: SelectOptionWithColor<number>[]) => onSelect(value)
    : (value: SelectOptionWithColor<number> | null) => {
        onSelect(value)
      }

  const renderLoadingMessage = () => `${i18n('common.loading')}...`

  const formatOptions = useCallback(
    (option: SelectOptionWithColor<number>) => (
      <ShiftTagItem color={option.color} label={option.label} />
    ),
    [],
  )

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect={false}
      customComponents={{
        ValueContainer,
        Option: ShiftTagOption,
        MultiValue: ShiftTagMultiValue,
      }}
      formatOptionLabel={formatOptions}
      isClearable={isClearable}
      isLoading={fetching}
      isMulti={isMulti}
      isSearchable
      loadingMessage={renderLoadingMessage}
      options={defaultOptions}
      value={value}
      withPortal={withPortal}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...rest}
    />
  )
}
