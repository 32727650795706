import styled from 'styled-components'

import { COLUMN_WIDTH } from '../../constants'

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights[3]};
  padding: 10px;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${COLUMN_WIDTH}px);
  border: 1px solid ${({ theme }) => theme.colors.OUTLINE_BASE};
  border-left: none;
  border-right: none;
  align-items: center;

  ${Title} {
    border-right: 1px solid ${({ theme }) => theme.colors.OUTLINE_BASE};
    &:last-child {
      border-right: none;
    }
  }
`
