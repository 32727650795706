import React from 'react'

import { flexRender } from '@tanstack/react-table'

import { Th, Thead, Tr } from './styles'
import { DataTableProps } from './types'

export function TableHeader<T>({ table }: DataTableProps<T>) {
  return (
    <Thead>
      {table.getHeaderGroups().map(headerGroup => (
        <Tr key={headerGroup.id}>
          {headerGroup.headers.map(header => {
            return (
              <Th
                colSpan={header.colSpan}
                key={header.id}
                {...table.options.meta}
                {...header.column.columnDef.meta}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Th>
            )
          })}
        </Tr>
      ))}
    </Thead>
  )
}
