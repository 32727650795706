import { DateTime } from 'luxon'

export function isDateInFuture({
  date,
  timezone,
}: {
  date: string
  timezone: string
}) {
  const dateTime = DateTime.fromISO(date).setZone(timezone)

  if (!dateTime.isValid || dateTime < DateTime.now().setZone(timezone))
    return false

  return true
}
