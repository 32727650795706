import { useAvailableEmployeesForShiftJobByCursor } from './useAvailableEmployeesForShiftJobByCursor'
import { useChangeEmployeeEmail } from './useChangeEmployeeEmail'
import { useEmployeeActivation } from './useEmployeeActivation'
import { useEmployeeRelocation } from './useEmployeeRelocation'
import { useEmployeeWithShiftJobs } from './useEmployeeWithShiftJobs'
import { useLazyEmployeesByCursor } from './useLazyEmployeesByCursor'
import { useSuggestedEmployeesForCoverPicker } from './useSuggestedEmployeesForCoverPicker'

export const EmployeeAPI = {
  activate: useEmployeeActivation,
  relocate: useEmployeeRelocation,
  availableForShiftByCursor: useAvailableEmployeesForShiftJobByCursor,
  byCursorLazy: useLazyEmployeesByCursor,
  byIdWithShiftJobs: useEmployeeWithShiftJobs,
  changeEmail: useChangeEmployeeEmail,
  suggestedForCoverPicker: useSuggestedEmployeesForCoverPicker,
}
