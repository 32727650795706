import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { ShiftTagsPickerV2 } from 'components/blocks/__v3__/ShiftTagsPickerV2'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { Popover } from 'components/ui/__v3__/Popover'

import { TagsBadge } from './styles'

type Props = {
  labelText?: string
  required?: boolean
  disabled?: boolean
  width?: number
  minWidth?: number
  placeholder?: string
}

export function FormShiftTagsPickerPopover<T extends FieldValues>({
  control,
  disabled,
  name,
  labelText,
  required = false,
  ...rest
}: ControllerBaseProps<T> & Props) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const label = labelText || 'Tags'

  return (
    <Flex flexDirection="column">
      <InputLabel>
        <LabelText labelText={label} required={required} />
      </InputLabel>

      <Popover
        content={
          <Flex flexDirection="column">
            <InputLabel>
              <LabelText labelText={label} required={required} />
            </InputLabel>

            <ShiftTagsPickerV2
              isDisabled={disabled}
              isMulti
              isSearchable
              value={value}
              withPortal={false}
              onBlur={onBlur}
              onSelect={onChange}
              {...rest}
            />

            {error && <Error error={error} />}
          </Flex>
        }
        width={368}
      >
        <TagsBadge.Container disabled={disabled}>
          <TagsBadge.Icon />
          {/* @ts-ignore */}
          <Span>{`${value?.length ?? 0} tags`}</Span>
        </TagsBadge.Container>
      </Popover>
    </Flex>
  )
}
