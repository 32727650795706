import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth0Client, RedirectLoginOptions } from '@auth0/auth0-spa-js'
import {
  GetSessionStateQuery,
  SignInMutation,
  SignOutMutation,
} from 'API/Auth/GraphQL'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { auth0, PR_NUMBER } from 'constants/config'
import { PR_AUTH_PATH_PATTERN, PR_PATH_PATTERN } from 'constants/regexes'

import { i18n } from 'i18n'

import LocalStorage from 'services/LocalStorage'
import { showToast } from 'services/Toasts'

import { SessionManager } from './SessionManager'
import { AuthAppState, IdTokenClaims } from './types'

import { gatewayHttpLink } from '../Apollo/Links/gatewayHttpLink'
import { isDawamyEnvironment } from '../Brand'

// TODO: add unit tests for auth
class Auth {
  private readonly auth0Client = new Auth0Client({
    clientId: auth0.clientId,
    domain: auth0.domain,
    cacheLocation: 'localstorage', // Problems with cookies on localhost
    authorizationParams: {
      prompt: 'select_account',
      audience: auth0.audience,
      redirect_uri: auth0.redirectUri,
    },
  })

  private readonly apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: false,
  })

  private readonly TIME_TO_MAKE_API_CALL = 5 * 1000

  private readonly localStorageKeys = {
    authError: 'workaxle-auth-error',
    prRedirectPath: 'workaxle-pr-redirect-path',
    prLogoutRedirectUrl: 'workaxle-pr-logout-redirect-url',
  }

  private sessionManager?: SessionManager

  private accessToken = ''

  private auth0AccessToken = ''

  private clusterId = ''

  private isAuthenticated = false

  private isLoading = true

  private from = ''

  public readonly UPDATE_AUTH_EVENT = 'update-auth-event'

  constructor() {
    this.exchangeAuth0ForGatewayToken = this.exchangeAuth0ForGatewayToken.bind(
      this,
    )
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getAuthHeaders = this.getAuthHeaders.bind(this)
    this.refreshSessionStateWithAuth0Token = this.refreshSessionStateWithAuth0Token.bind(
      this,
    )
    this.isAccessTokenExpired = this.isAccessTokenExpired.bind(this)
    this.logoutFromAuth0 = this.logoutFromAuth0.bind(this)
    this.logout = this.logout.bind(this)
    this.clearOnLogout = this.clearOnLogout.bind(this)
    this.handleSessionUpdate = this.handleSessionUpdate.bind(this)
    this.onSessionUpdate = this.onSessionUpdate.bind(this)
    this.onActivityCheck = this.onActivityCheck.bind(this)
    this.onSessionTimeout = this.onSessionTimeout.bind(this)
    this.initialize()
  }

  private async initialize() {
    const authError = LocalStorage.getItem(this.localStorageKeys.authError)

    if (authError) {
      // Timeout to make sure that locale is set
      setTimeout(() => {
        showToast({
          type: 'error',
          title: i18n('auth.error.title'),
          content: authError,
        })
      }, 0)

      LocalStorage.removeItem(this.localStorageKeys.authError)
    }

    const prLogoutRedirectPath =
      LocalStorage.getItem(this.localStorageKeys.prLogoutRedirectUrl) ?? ''

    if (PR_PATH_PATTERN.test(prLogoutRedirectPath)) {
      LocalStorage.removeItem(this.localStorageKeys.prLogoutRedirectUrl)
      window.location.href = prLogoutRedirectPath
      return Promise.resolve()
    }

    const auth0SearchString =
      location.search.includes('state=') &&
      (location.search.includes('code=') || location.search.includes('error='))

    if (auth0SearchString) {
      try {
        const {
          appState,
        } = await this.auth0Client.handleRedirectCallback<AuthAppState>()

        if (appState?.from) {
          if (PR_PATH_PATTERN.test(appState.from)) {
            window.location.href = window.location.origin + appState.from
            return Promise.resolve()
          }
          this.from = appState.from
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message ===
          // This message comes from custom action https://manage.auth0.com/dashboard/us/workaxle-dev/actions/library
          'This aunthentication method is not allowed'
            ? i18n('auth.error.notAllowed')
            : i18n('auth.error.invalidCredentials')

        LocalStorage.setItem(this.localStorageKeys.authError, errorMessage)

        return this.logoutFromAuth0()
      }
    }

    const isAuth0Authenticated = await this.auth0Client.isAuthenticated()

    if (isAuth0Authenticated) {
      const prRedirectPath = LocalStorage.getItem(
        this.localStorageKeys.prRedirectPath,
      )

      if (prRedirectPath) {
        LocalStorage.removeItem(this.localStorageKeys.prRedirectPath)
        window.location.href = prRedirectPath
        return Promise.resolve()
      }

      const idTokenClaims:
        | IdTokenClaims
        | undefined = await this.auth0Client.getIdTokenClaims()

      this.clusterId = idTokenClaims?.clusterId ?? ''
      // Set apollo links here, to make sure that first request will be with clusterId
      this.apolloClient.setLink(
        ApolloLink.from([
          setContext(async (_, { headers }) => ({
            headers: {
              ...headers,
              'cluster-id': this.clusterId,
            },
          })),
          gatewayHttpLink(),
        ]),
      )
      this.auth0AccessToken = await this.auth0Client.getTokenSilently()

      const sessionState = await this.exchangeAuth0ForGatewayToken()

      if (sessionState.accessToken) {
        this.accessToken = sessionState.accessToken
        this.isAuthenticated = true

        this.sessionManager = new SessionManager(
          {
            onTimeout: this.onSessionTimeout,
            onSessionUpdate: this.onSessionUpdate,
            onActivityCheck: this.onActivityCheck,
          },
          sessionState.duration,
        )
      }
    } else if (PR_AUTH_PATH_PATTERN.test(window.location.href)) {
      LocalStorage.setItem(
        this.localStorageKeys.prRedirectPath,
        window.location.href,
      )
    }

    this.isLoading = false
    // To prevent safari bug -----------------------------------------------
    const isSafari = navigator.userAgent.includes('Safari')
    const timeout = isSafari ? 100 : 0

    return setTimeout(() => {
      window.postMessage(this.UPDATE_AUTH_EVENT, '*')
    }, timeout)
    // ---------------------------------------------------------------------
  }

  // ==========================================================================
  // Public methods
  // ==========================================================================
  public loginWithRedirect(options: RedirectLoginOptions<any>) {
    this.auth0Client.loginWithRedirect(options)
  }

  public getState() {
    return {
      isLoading: this.isLoading,
      isAuthenticated: this.isAuthenticated,
      from: this.from,
    }
  }

  public async getAccessToken() {
    const accessTokenExpired = this.isAccessTokenExpired()

    if (accessTokenExpired) {
      await this.refreshSessionStateWithAuth0Token()
    }

    return this.accessToken
  }

  public getAuth0Token() {
    return this.auth0AccessToken
  }

  public async getAuthHeaders() {
    const token = this.isAuthenticated ? await this.getAccessToken() : ''

    return {
      Authorization: `Bearer ${token}`,
      'cluster-id': this.clusterId,
    }
  }

  public onSessionTimeout() {
    this.logoutFromAuth0()
  }

  // TODO: add spinner
  public async logout() {
    this.sessionManager?.notifyLogout() // Notify other tabs to logout
    await this.logoutFromWorkAxle()
    await this.clearOnLogout()
    this.saveLogoutRedirectUrl() // Note: Need only for dev pr deployment redirect
    this.logoutFromAuth0() // Will redirect to auth0 page and back to the app
  }

  // ==========================================================================
  // Private methods
  // ==========================================================================

  private saveLogoutRedirectUrl() {
    if (PR_PATH_PATTERN.test(window.location.href)) {
      LocalStorage.setItem(
        this.localStorageKeys.prLogoutRedirectUrl,
        `${window.location.origin}/pr-${PR_NUMBER}/auth`,
      )
    }
  }

  private onSessionUpdate(state: Gateway.SessionState) {
    this.handleSessionUpdate(state)
  }

  private handleSessionUpdate(state: Gateway.SessionState) {
    this.accessToken = state.accessToken
  }

  private async clearOnLogout() {
    this.sessionManager?.cleanup()
    LocalStorage.clear()
  }

  private async logoutFromWorkAxle() {
    try {
      await this.apolloClient.mutate<
        MutationData<'signOut'>,
        Gateway.MutationSignOutArgs
      >({
        mutation: SignOutMutation,
        variables: { auth0Token: this.auth0AccessToken },
      })
    } catch (error) {
      // TODO: possible edge case, add logging
    }
  }

  private async logoutFromAuth0() {
    this.auth0Client.logout({
      logoutParams: {
        returnTo: auth0.redirectUri,
        federated: isDawamyEnvironment(),
      },
    })
  }

  private isAccessTokenExpired() {
    try {
      const { exp } = jwtDecode<JwtPayload>(this.accessToken)
      const expiresIn = Number(exp) * 1000
      // + TIME_TO_MAKE_API_CALL to make sure that we will not receive Unathorized response
      const isExpired = Date.now() + this.TIME_TO_MAKE_API_CALL > expiresIn

      return isExpired
    } catch (error) {
      return true
    }
  }

  private async refreshSessionStateWithAuth0Token() {
    const sessionState = await this.exchangeAuth0ForGatewayToken()

    this.accessToken = sessionState.accessToken
    this.sessionManager?.updateSession(sessionState)
  }

  private async exchangeAuth0ForGatewayToken(): Promise<Gateway.SessionState> {
    try {
      const { data } = await this.apolloClient.mutate<
        MutationData<'signIn'>,
        Gateway.MutationSignInArgs
      >({
        mutation: SignInMutation,
        variables: { auth0Token: this.auth0AccessToken },
      })

      return (
        data?.signIn ?? {
          duration: 0,
          accessToken: '',
        }
      )
    } catch (error) {
      const errorMessage = (error as Error).message

      LocalStorage.setItem(this.localStorageKeys.authError, errorMessage)

      this.logoutFromAuth0()

      return {
        duration: 0,
        accessToken: '',
      }
    }
  }

  private onActivityCheck() {
    const isAccessTokenExpired = this.isAccessTokenExpired()

    if (isAccessTokenExpired) {
      this.refreshSessionStateWithAuth0Token()
    } else {
      this.getSessionState()
    }
  }

  private async getSessionState() {
    try {
      const { data } = await this.apolloClient.query<
        QueryData<'getSessionState'>,
        Gateway.QueryGetSessionStateArgs
      >({
        query: GetSessionStateQuery,
        variables: { auth0Token: this.auth0AccessToken },
        context: {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        },
        fetchPolicy: 'network-only',
      })

      const sessionState = {
        duration: data?.getSessionState?.duration ?? 0,
        accessToken: data?.getSessionState?.accessToken ?? '',
      }

      this.sessionManager?.updateSession(sessionState)
      this.accessToken = sessionState.accessToken
    } catch (error) {
      await this.clearOnLogout()
      const errorMessage = (error as Error).message
      LocalStorage.setItem(this.localStorageKeys.authError, errorMessage)
      this.logoutFromAuth0()
    }
  }
}

export const AuthService = new Auth()
