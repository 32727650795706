/* eslint-disable no-console */
export function createWorker(workerFunction: () => void) {
  if (typeof Worker === 'undefined') {
    console.error('Workers not supported in this browser')
    return null
  }

  try {
    const code = `(${workerFunction.toString()})()`
    const blob = new Blob([code], { type: 'application/javascript' })
    const workerUrl = URL.createObjectURL(blob)
    const worker = new Worker(workerUrl)
    console.log('Worker created successfully')
    URL.revokeObjectURL(workerUrl)

    worker.onerror = error => {
      console.error('Worker error:', error)
    }

    return worker
  } catch (error) {
    console.error('Failed to create worker:', error)
    return null
  }
}

// export function createSharedWorker(workerFunction: () => void) {
//   if (typeof SharedWorker === 'undefined') {
//     console.error('Shared Workers not supported in this browser')
//     return null
//   }

//   //   const code = worker.toString()
//   //   const blob = new Blob([`(${code})()`])
//   //   return new SharedWorker(URL.createObjectURL(blob))

//   try {
//     // const code = `(${workerFunction.toString()}).default()`
//     // const blob = new Blob([code], { type: 'application/javascript' })
//     const code = workerFunction.toString()
//     const blob = new Blob([`(${code})()`])
//     const workerUrl = URL.createObjectURL(blob)
//     const worker = new SharedWorker(workerUrl)
//     console.log('Shared Worker created successfully')
//     // URL.revokeObjectURL(workerUrl)

//     // worker.onerror = error => {
//     //   console.error('Shared Worker error:', error)
//     // }

//     return worker
//   } catch (error) {
//     console.error('Failed to create shared worker:', error)
//     return null
//   }
// }

export const createSharedWorker = (worker: any) => {
  const code = worker.toString()
  const blob = new Blob([`(${code})()`])
  return new SharedWorker(URL.createObjectURL(blob))
}
