import { useCallback, useMemo, useState } from 'react'

import { PAGE_SIZE, PaginationSizeOptions } from 'constants/pagination'

const INITIAL_NUMBER = 1

export function useOffsetPaginationV2(initialSize?: PaginationSizeOptions) {
  const INITIAL_SIZE = initialSize ?? PAGE_SIZE[10]
  const [number, setNumber] = useState(INITIAL_NUMBER)
  const [size, setSize] = useState<PaginationSizeOptions>(INITIAL_SIZE)

  const offset = size * (number - 1)

  const onOffsetChange = (offset: number) => {
    setNumber(offset / size + 1)
  }

  const onLimitChange = (limit: PaginationSizeOptions) => {
    setSize(limit)
    setNumber(1)
  }

  const onReset = useCallback(() => {
    setNumber(INITIAL_NUMBER)
    setSize(INITIAL_SIZE)
  }, [INITIAL_SIZE])

  const page = useMemo(() => ({ number, size }), [number, size])

  return { page, onOffsetChange, onLimitChange, onReset, offset, limit: size }
}
