import { areasFilterToGraphql } from 'API/services/utils'

import { WorkStatusFilter } from 'pages/EmployerApp/Schedule/pages/EmployeesDaily/constants'

import Utils from 'services/Utils'

import { ScheduleFilter } from '../types'

export function scheduleFilterToAPI(
  stateFilters: ScheduleFilter,
): Gateway.ScheduleFilter {
  const apiFilter: Gateway.ScheduleFilter = stateFilters.areas
    ? {
        // NOTE: areasFilterToGraphql returns locationId, we don't need locations here
        ...areasFilterToGraphql({
          departments: stateFilters.areas.departments,
          jobs: stateFilters.areas.jobs,
        }),
      }
    : {}

  if (stateFilters.areas?.locations) {
    // NOTE: here we need a branchId
    if (Array.isArray(stateFilters.areas.locations)) {
      apiFilter.branchId =
        stateFilters.areas.locations.length > 0
          ? { in: stateFilters.areas.locations.map(location => location.id!) }
          : undefined
    } else {
      apiFilter.branchId = { in: [stateFilters.areas.locations.id] }
    }
  }

  if (stateFilters.selectedEmployees?.length) {
    apiFilter.employeeId = {
      in: stateFilters.selectedEmployees.map(employee => employee.value),
    }
  }

  if (stateFilters.startOn) {
    apiFilter.startOn = {
      between: [stateFilters.startOn.from, stateFilters.startOn.to],
    }
  }

  if (stateFilters.shiftJobId) {
    apiFilter.shiftJobId = Utils.GraphQL.eqFilter(stateFilters.shiftJobId)
  }

  if (stateFilters.shiftTags?.length) {
    const shiftTagIds = stateFilters.shiftTags.map(({ value }) =>
      value.toString(),
    )
    apiFilter.shiftTagIds = {
      ...(stateFilters.shiftTagsFilterMode === 'or'
        ? { any: shiftTagIds }
        : { all: shiftTagIds }),
    }
  }

  if (stateFilters.state) {
    apiFilter.state = {
      eq: stateFilters.state,
    }
  }

  if (
    stateFilters.workStatus &&
    (stateFilters.workStatus === WorkStatusFilter.OnBreak ||
      stateFilters.workStatus === WorkStatusFilter.NotOnBreak)
  ) {
    apiFilter.onPause = {
      eq: stateFilters.workStatus === WorkStatusFilter.OnBreak,
    }
  }

  if (stateFilters.startWithinRange) {
    apiFilter.startAt = Utils.GraphQL.betweenFilter({
      from: stateFilters.startWithinRange.from,
      to: stateFilters.startWithinRange.to,
    })
  }

  return apiFilter
}
