import { useApolloLazyQuery } from 'API/services/Apollo'

import isNil from 'lodash/isNil'

import {
  ScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import { SchedulesPickerQuery, SchedulesPickerQueryData } from '../GraphQL'
import { SchedulePickerFields } from '../GraphQL/Fragments/types'

type FilterProps = {
  employeeId?: string
  startDate?: string
  branchIds?: string[]
  mayBeCovered?: boolean
  mayBeTraded?: boolean
}

export function useSchedulesPicker() {
  const [
    loadSchedulesPickerInner,
    { loading: schedulesPickerLoading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    SchedulesPickerQueryData,
    Gateway.QuerySchedulesByCursorArgs
  >(SchedulesPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadSchedules = ({
    employeeId,
    startDate,
    branchIds,
    mayBeCovered,
    mayBeTraded,
  }: FilterProps) => {
    loadSchedulesPickerInner({
      variables: {
        paging: { limit: PAGE_SIZE['25'] },
        sorting: [
          {
            direction: SortingDirection.Asc,
            field: ScheduleSortingField.ShiftStartAt,
          },
        ],
        filter: {
          ...(employeeId && {
            employeeId: Utils.GraphQL.eqFilter(employeeId),
          }),
          ...(startDate && {
            startOn: Utils.GraphQL.betweenFilter({
              from: startDate,
              to: startDate,
            }),
          }),
          ...(branchIds && {
            branchId: Utils.GraphQL.inFilter(branchIds),
          }),
          ...(!isNil(mayBeCovered) && {
            mayBeCovered: Utils.GraphQL.eqFilter(mayBeCovered),
          }),
          ...(!isNil(mayBeTraded) && {
            mayBeTraded: Utils.GraphQL.eqFilter(mayBeTraded),
          }),
        },
      },
    })
  }

  const refetchSchedules = async ({ employeeId, startDate }: FilterProps) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: {
        ...(employeeId && {
          employeeId: Utils.GraphQL.eqFilter(employeeId),
        }),
        ...(startDate && {
          startOn: Utils.GraphQL.betweenFilter({
            from: startDate,
            to: startDate,
          }),
        }),
      },
    })
    return dataToNodes(result.data)
  }

  const schedules = dataToNodes(data)

  return {
    schedules,
    schedulesPickerLoading,
    refetchSchedules,
    loadSchedules,
  }
}

function dataToNodes(data?: SchedulesPickerQueryData): SchedulePickerFields[] {
  return data?.schedulesByCursor?.edges.map(edge => edge.node) ?? []
}
