import React, { useLayoutEffect, useRef, useState } from 'react'

import { Container, Elem, Toggle } from './styles'

type SwitchProps = {
  checked: boolean
  onChange: (checked: boolean) => void
  firstLabel: string
  secondLabel: string
}
export function ToggleButton({
  checked,
  onChange,
  firstLabel,
  secondLabel,
}: SwitchProps) {
  const firstLabelRef = useRef<HTMLDivElement>(null)
  const secondLabelRef = useRef<HTMLDivElement>(null)

  const [buttonWidth, setButtonWidth] = useState<number>()

  useLayoutEffect(() => {
    const firstLabelWidth = firstLabelRef.current?.getBoundingClientRect().width
    const secondLabelWidth = secondLabelRef.current?.getBoundingClientRect()
      .width
    if (!firstLabelWidth || !secondLabelWidth) return

    setButtonWidth(Math.max(firstLabelWidth, secondLabelWidth))
  }, [firstLabelRef, secondLabelRef])

  return (
    <Container onClick={() => onChange(!checked)}>
      <Toggle checked={checked} width={buttonWidth}>
        {checked ? secondLabel : firstLabel}
      </Toggle>

      <Elem ref={firstLabelRef} width={buttonWidth}>
        {firstLabel}
      </Elem>
      <Elem ref={secondLabelRef} width={buttonWidth}>
        {secondLabel}
      </Elem>
    </Container>
  )
}
