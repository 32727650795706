/* eslint-disable prefer-destructuring */

import { main } from 'theme/main'

import buttons from './buttons'
import * as colors from './colors'
import { input } from './input'
import labels from './labels'
import { layout } from './layout'
import links from './links'
import { selects } from './selects'
import { table } from './table'
import { toasts } from './toasts'
import { zIndices } from './zIndices'

import { spacing } from '../spacing'

/**
 * @see https://styled-system.com/theme-specification#key-reference
 */
export const theme = {
  // TODO: remove when all screen migrate to new UI kit
  // Migrate old theme values to support legacy components
  ...main,
  font: "'Roboto', sans-serif",
  fonts: ["'Roboto', sans-serif"],
  breakpoints: [32, 48, 64, 80],
  space: [0, 4, 8, 12, 16, 32, 64, 128],
  spacing,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  fontWeights: [300, 400, 600, 700],
  gradient: '135deg',
  radii: [4, 23],
  name: 'Light Theme',
  sizes: {
    menu: {
      folded: '48px',
      unfolded: '228px',
    },
    header: '48px',
    profileBar: '200px',
  },
  /**
   * Use component accessor to colors instead of using direct access.
   * That way theme can be safely modified without changing dozen of files.
   * @see {@link theme.button}, {@link theme.links} etc...
   */
  colors: {
    // TODO: remove when all screen migrate to new UI kit
    // Migrate old theme colors to support legacy components
    ...main.colors,
    ...colors,
  },
  layout,
  toasts,
  labels,
  buttons,
  selects,
  table,
  links,
  zIndices,
  input,
  checkbox: {
    bg: {
      default: colors.WHITE,
      checked: colors.SMASHED_PUMPKIN,
      disabled: colors.SEASHELL,
    },
    border: {
      default: colors.WHISPER_GRAY,
      checked: colors.SMASHED_PUMPKIN,
      disabled: colors.NOBEL,
    },
    icon: {
      border: {
        default: colors.PAPER_WHITE_20,
        disabled: colors.SPUN_PEARL,
      },
    },
  },
  radio: {
    bg: {
      default: colors.PAPER_WHITE_20,
      checked: colors.SMASHED_PUMPKIN,
      disabled: colors.CHARCOAL_40,
    },
    border: {
      default: colors.CHARCOAL_40,
      checked: colors.SMASHED_PUMPKIN,
      disabled: colors.CHARCOAL_40,
    },
    icon: {
      bg: {
        default: colors.PAPER_WHITE_20,
        disabled: colors.CHARCOAL_60,
      },
    },
  },
  switch: {
    bg: {
      default: colors.SPUN_PEARL,
      checked: {
        primary: colors.SMASHED_PUMPKIN,
        secondary: colors.POSITIVE,
      },
      disabled: colors.NOBEL,
    },
    border: {
      default: colors.CHARCOAL_40,
      checked: colors.SMASHED_PUMPKIN,
      disabled: colors.CHARCOAL_40,
    },
    icon: {
      bg: {
        default: colors.PAPER_WHITE_20,
        disabled: colors.CHARCOAL_60,
      },
    },
  },
  divider: {
    color: colors.ATHENS_GRAY_40,
  },
  search: {
    icon: {
      color: colors.SMASHED_PUMPKIN,
    },
  },
  modal: {
    overlay: {
      bg: 'rgba(34, 35, 56, 0.5)',
    },
    scrollbar: {
      bg: 'rgb(255, 255, 255)',
      thumb: 'rgb(215, 215, 215)',
    },
  },
  icons: {
    success: colors.POSITIVE,
    error: colors.NEGATIVE,
    primary: colors.SMASHED_PUMPKIN,
    secondary: colors.CHARCOAL_40,
    neutral: colors.NEUTRAL,
  },
  tooltip: {
    bg: colors.WHITE,
    color: colors.TEXT,
    shadow: '0 0 14px 0 rgba(0, 0, 0, 0.13)',
  },
  date: {
    bg: colors.WHITE,
    border: colors.CHARCOAL_40,
    color: colors.TEXT,
    placeholder: {
      color: colors.CHARCOAL_60,
    },
    shadow: '0 4px 5px 0 rgba(0, 0, 0, 0.1)',
    weekday: {
      color: colors.SMASHED_PUMPKIN,
    },
    day: {
      bg: colors.SMASHED_PUMPKIN,
      color: colors.WHITE,
      disabled: 'transparent',
      selected: {
        bg: colors.PUMPKIN_20,
        color: colors.TEXT,
      },
    },
    today: {
      color: colors.NEUTRAL,
    },
  },
  mark: {
    color: colors.SMASHED_PUMPKIN,
    background: 'transparent',
  },
  grid: {
    background: colors.PAPER_WHITE_100,
  },
}

/**
 * Scale Aliases
 * @see https://styled-system.com/theme-specification#scale-aliases
 */

const fontWeightIt = theme.fontWeights[Symbol.iterator]()
theme.fontWeights.light = fontWeightIt.next().value
theme.fontWeights.normal = fontWeightIt.next().value
theme.fontWeights.semi = fontWeightIt.next().value
theme.fontWeights.bold = fontWeightIt.next().value

const fontsIt = theme.fonts[Symbol.iterator]()
theme.fonts.main = fontsIt.next().value

theme.fontSizes.sectionHeader = theme.fontSizes[5]

const radiiIt = theme.radii[Symbol.iterator]()
theme.radii.normal = radiiIt.next().value
theme.radii.round = radiiIt.next().value

export default theme
