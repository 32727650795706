import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

export const Container = styled.div<LayoutProps>`
  display: flex;
  height: 32px;
  padding: 2px 18px;
  gap: 26px;
  align-items: center;
  position: relative;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.CHARCOAL_40};
  background: ${({ theme }) => theme.colors.ATHENS_GRAY_20};

  ${layout}
`

type WidthProps = {
  width?: number
}

type ToggleProps = WidthProps & {
  checked: boolean
}

export const Toggle = styled.div<ToggleProps>`
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: ${props => (props.checked ? 'calc(50% - 2px)' : '1px')};
  top: 50%;
  transform: translateY(-50%);
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  background: ${({ theme }) => theme.colors.EBONY_CLAY};
  color: ${({ theme }) => theme.colors.WHITE};
  user-select: none;
  width: ${props => (props.width ? `calc(${props.width}px + 32px)` : 'auto')};

  z-index: ${({ theme }) => theme.zIndices.planeUpper};
  transition: all 0.15s ease-in-out;

  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
`

export const Elem = styled.div<WidthProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.EBONY_CLAY};
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  user-select: none;
`
