import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { ShiftTradeState } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  ShiftTradesByCursorQuery,
  ShiftTradesByCursorQueryData,
} from './GraphQL'

type Props = {
  paging: CursorPage
  filter: {
    state?: ShiftTradeState
    interval?: {
      from: string
      to: string
    }
    employeeId?: string
  }
  sorting: Gateway.ShiftTradeSorting
}

export function useShiftTradesByCursor({ paging, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    ShiftTradesByCursorQueryData,
    Gateway.QueryShiftTradesByCursorArgs
  >(ShiftTradesByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...(filter?.state && {
          state: Utils.GraphQL.eqFilter(filter.state),
        }),
        ...(filter?.interval && {
          fromShiftStartAt: Utils.GraphQL.betweenFilter({
            from: filter.interval.from,
            to: filter.interval.to,
          }),
        }),
        ...(filter?.employeeId && {
          fromEmployeeId: Utils.GraphQL.eqFilter(filter.employeeId),
        }),
      },
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
    },

    onCompleted(data) {
      const newPageMeta = data.shiftTradesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const shiftTrades = useMemo(
    () => data?.shiftTradesByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    shiftTrades,
    loading,
    currentPageMeta,
    refetch,
  }
}
