import React from 'react'

import { LayoutProps, SpaceProps } from 'styled-system'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { Popover } from 'components/ui/__v3__/Popover'
import { TextArea } from 'components/ui/__v3__/TextArea'

import { i18n } from 'i18n'

import { CommentsBadge } from './styles'

type Props = {
  labelText?: string
  required?: boolean
  disabled?: boolean
  value?: string
  onBlur?: () => void
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  renderErrorIfExists?: () => React.ReactNode
} & SpaceProps &
  LayoutProps

export function TextAreaPopover({
  disabled,
  labelText,
  required = false,
  value,
  onBlur,
  onChange,
  renderErrorIfExists,
  ...rest
}: Props) {
  return (
    <Flex flexDirection="column" {...rest}>
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}

      <Popover
        content={
          <Flex flexDirection="column">
            {/* @ts-ignore */}
            <TextArea
              autoFocus
              disabled={disabled}
              required={required}
              style={{
                width: '100%',
                height: 170,
              }}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              {...rest}
            />

            {renderErrorIfExists?.()}
          </Flex>
        }
        width={192}
      >
        <CommentsBadge.Container disabled={disabled}>
          <CommentsBadge.Icon />
          <Span>{!value?.length && i18n('common.none')}</Span>
        </CommentsBadge.Container>
      </Popover>
    </Flex>
  )
}
