export enum ProfileFeatures {
  // User Info
  UserInfoAside = 'userInfoAside', // That's a trick to make sure it is always displayed
  ChangeAvatar = 'changeAvatar',
  ChangePassword = 'changePassword',
  Archive = 'archive',
  RemoveFromLocation = 'removeFromLocation',
  ChangeFirstLastName = 'changeFirstLastName',
  ChangeEmail = 'changeEmail',
  FaceId = 'faceId',
  ChangeAccessLevel = 'changeAccessLevel',
  ViewPin = 'viewPin',
  ProfileInfo = 'profileInfo',
  Gender = 'gender',
  Supervisors = 'supervisors',
  TimesheetCode = 'timesheetCode',
  DateOfHiring = 'dateOfHiring',
  DateOfBirth = 'dateOfBirth',
  Username = 'username',
  Address = 'address',
  RoleEffectiveDates = 'roleEffectiveDates',

  // General tab
  Roles = 'roles',
  AdditionalInformation = 'additionalInformation',
  RoleTags = 'roleTags',
  Statistics = 'statistics',

  // Locations tab
  LocationsList = 'locationsList',
  RelocationRequest = 'relocationRequest',
  // Availabilities
  AvailabilitySchedule = 'availabilitySchedule',

  // Emergency
  HealthFile = 'health_file',
  ERContact = 'emergency_contact',
  // Administrative
  DisciplinaryTable = 'disciplinary_table',
  DisciplinaryNotes = 'disciplinary_notes',
  // Salary Revision
  SalaryTable = 'salary_table',
  AnnualPerformanceTable = 'annual_performance_table',
  WorkAgreementTable = 'work_agreement_table',
  // CNESST
  // CNESSTTable = 'CNESST_table',
  // Logs
  Logs = 'logs',
  // TimeOff
  TimeOffTable = 'timeoff_table',
  // Groups, WorkSchedules, WorkWeeks & Timetypes
  Groups = 'groups',
  DeluxeSync = 'deluxeSync',
  EmployeeAvailabilityRequests = 'employeeAvailabilityRequests',
  // FPS Qualification Tab
  FPSQualifications = 'fpsQualifications',
}

export enum ScheduleFeatures {
  ManagementSchedules = 'managementSchedules',
  AccessScheduleTab = 'accessScheduleTab',
  AutoAssign = 'autoAssign',
  PublishSchedules = 'publishSchedules',
}

export enum StaffManagementFeatures {
  AccessPeopleTab = 'accessPeopleTab',
  InvitePeople = 'invite_people',
  ChangeTemporaryAccessLevel = 'changeTemporaryAccessLevel',
  ExportPeople = 'exportPeople',
}

export enum TimeCardFeatures {
  AccessTimecardSummary = 'accessTimecardSummary',
}

export enum WeeklyTimesheetsFeatures {
  DeleteWeeklyTimesheet = 'deleteWeeklyTimesheet',
}

export enum PayrollManagementFeatures {
  AccessPayrollTab = 'accessPayrollTab',
}
export enum TimeoffFeatures {
  AccessTimeOffTab = 'accessTimeOffTab',
  AssignTimeOff = 'assignTimeOff',
  RequestTimeOff = 'requestTimeOff',
  AccessTimeoffNotifications = 'accessTimeoffNotifications',
}

export enum BuilderFeatures {
  AccessReportBuilderTab = 'accessReportBuilderTab',
}

export enum IntegrationsFeatures {
  AccessIntegrationEventsTab = 'accessIntegrationEventsTab',
}

export enum CustomFieldsFeatures {
  AccessCustomFieldValuesTab = 'accessCustomFieldValuesTab',
}

export enum SettingsFeatures {
  AccessToken = 'accessToken',
}

export enum TimeBucketFeatures {
  SelectTimeBuckets = 'selectTimeBuckets',
  AccessTimeBucketName = 'accessTimeBucketName',
}

export enum ShiftTradeCoverFeatures {
  AccessShiftTradeCoverTab = 'accessShiftTradeCoverTab',
  AccessShiftTradeTab = 'accessShiftTradeTab',
  AccessShiftCoverTab = 'accessShiftCoverTab',
}
