import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

interface CustomProps {
  light?: boolean
  asText?: boolean
}

type Props = CustomProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  TypographyProps

export const InputBlock = styled.div<Props>`
  font-size: ${themeGet('fontSizes.0')}px;
  color: ${themeGet('input.label.color')};

  ${layout};
  ${space};
  ${flexbox};
  ${typography}
  ${position};
`
