import React from 'react'

import { DateTime } from 'luxon'

import { Flex } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks/useI18n'

import { TRANSLATIONS } from 'i18n'

import { CalendarIcon, Description, Section, Title } from './styles'

const DATE_FORMAT = 'MMM d'

type Props = {
  employeeName: string
  period: {
    start: string
    end: string
  }
}
export function Info({ employeeName, period }: Props) {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.simulationModal>(
    'weeklyTimesheets.simulationModal',
  )

  return (
    <Flex gap={4} mt={4} width="100%">
      <Section>
        <Title>{t('employee')}</Title>
        <Description>{employeeName}</Description>
      </Section>

      <Section>
        <Title>{t('period')}</Title>
        <Description>
          <CalendarIcon />
          {`${DateTime.fromISO(period.start).toFormat(
            DATE_FORMAT,
          )} - ${DateTime.fromISO(period.end).toFormat(DATE_FORMAT)}`}
        </Description>
      </Section>
    </Flex>
  )
}
