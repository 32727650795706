import { CursorPage } from 'Types/common'

import { PaginationSizeOptions } from 'constants/pagination'

import { denormalize } from 'helpers/redux'

import Utils from 'services/Utils'

export function transformResponseToEntities<T>({
  response,
  jsonApiType,
}: {
  response: JsonApi.Response
  jsonApiType: string
}) {
  const data = response?.payload?.data

  const entityIds = Utils.JsonApi.resultToSortedIds(response)

  const entities: Array<T> = data?.[jsonApiType]
    ? denormalize(data, jsonApiType, entityIds)
    : []

  return {
    entities,
  }
}

export function getResponseMeta(response: JsonApi.Response) {
  const data = response?.payload?.data
  // @ts-ignore
  return Object.values(data?.meta)[0]?.meta
}

export function getOffsetPageMeta(response: JsonApi.Response) {
  const { recordCount, nextPage }: JsonApi.OffsetPaging = getResponseMeta(
    response,
  )

  return { recordCount: recordCount ?? 0, nextPage }
}

export function getCursorPageMeta(response: JsonApi.Response) {
  const meta: JsonApi.CursorPaging = getResponseMeta(response)
  return meta
}

export function getNextCursorPageParam(
  page: JsonApi.Response,
  increment: PaginationSizeOptions,
): JsonApi.CursorPagingInput | undefined {
  const { hasNextPage, endCursor }: JsonApi.CursorPaging = getResponseMeta(page)
  if (!hasNextPage) return undefined
  return {
    starting_after: endCursor ?? undefined,
    size: increment,
  }
}

export function getPrevCursorPageParam(
  page: JsonApi.Response,
  increment: PaginationSizeOptions,
): JsonApi.CursorPagingInput | undefined {
  const {
    hasPreviousPage,
    startCursor,
  }: JsonApi.CursorPaging = getResponseMeta(page)
  if (!hasPreviousPage) return undefined
  return {
    ending_before: startCursor ?? undefined,
    size: increment,
  }
}

export function cursorPageToJsonApiPage(
  page: CursorPage,
): JsonApi.CursorPagingInput {
  return {
    size: page.size,

    ...(page.startingAfter
      ? { starting_after: page.startingAfter }
      : { ending_before: page.endingBefore }),
  }
}
