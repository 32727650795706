import styled from 'styled-components'
import { flexbox, FlexboxProps } from 'styled-system'

export const LoadMoreContainer = styled.div<FlexboxProps>`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[1]}px;
  align-items: center;
  justify-content: end;
  color: ${({ theme }) => theme.layout.darkBorder};

  ${flexbox}
`

export const LoadMoreHandler = styled.span`
  cursor: pointer;
`
