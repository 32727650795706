import { useFPSWorkersCouncilExport } from './useFPSWorkersCouncilExport'
import { useMissingQualificationsByCursor } from './useMissingQualificationsByCursor'
import { useScheduleExport } from './useScheduleExport'
import { useSchedulesByCursor } from './useSchedulesByCursor'
import { useSchedulesCount } from './useSchedulesCount'
import { useSchedulesGardaListView } from './useSchedulesGardaListView'
import { useSchedulesPicker } from './useSchedulesPicker'
import { useSchedulesShiftAssignments } from './useSchedulesShiftAssignments'
import { useShiftsWeeklySchedulesByCursor } from './useShiftsWeeklySchedulesByCursor'
import { useShiftTradeSuggestedSchedulesByCursor } from './useShiftTradeSuggestedSchedulesByCursor'
import { useUnpublishSchedules } from './useUnpublishSchedules'

export const ScheduleAPI = {
  byCursor: useSchedulesByCursor,
  byCursorForShiftsWeekly: useShiftsWeeklySchedulesByCursor,
  byCursorForGardaDaily: useSchedulesGardaListView,
  byCursorForShiftAssignments: useSchedulesShiftAssignments,
  schedulesCount: useSchedulesCount,
  scheduleExport: useScheduleExport,
  fpsWorkersCouncilExport: useFPSWorkersCouncilExport,
  missingQualificationsByCursor: useMissingQualificationsByCursor,

  picker: useSchedulesPicker,

  unpublishSchedules: useUnpublishSchedules,

  shiftTradeSuggestedSchedulesByCursor: useShiftTradeSuggestedSchedulesByCursor,
}
