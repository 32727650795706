import React, { useCallback } from 'react'

import { API } from 'API'
import { SelectOptionWithId } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { formatOptionLabel } from 'helpers/areas'
import { createpDebounce } from 'helpers/debounce'

import { entityToOptionWithId } from 'services/Options'

const SEARCH_DEBOUNCE_TIMEOUT = 350

type Props = Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'defaultOptions'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'onMenuOpen'
  | 'onChange'
  | 'value'
  | 'isMulti'
> &
  (
    | {
        isMulti: true
        onChange: (value: SelectOptionWithId[] | null) => void
        value: SelectOptionWithId[] | null
      }
    | {
        isMulti?: false
        onChange: (value: SelectOptionWithId | null) => void
        value: SelectOptionWithId | null
      }
  )

export function GroupsPicker({
  isMulti,
  withPortal = true,
  onChange,
  ...rest
}: Props) {
  const {
    groups,
    groupsLoading,
    loadGroups,
    refetchGroups,
  } = API.Group.picker()

  const defaultOptions = groups.map(entityToOptionWithId) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetchGroups(inputValue)
        const newOptions = refetchedData.map(entityToOptionWithId)

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetchGroups],
  )

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    refetchGroups()
  }

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel()}
      isLoading={groupsLoading}
      isMulti={isMulti}
      loadOptions={handleLoadOptions}
      withPortal={withPortal}
      onChange={onChange}
      onInputChange={handleInputChange}
      onMenuOpen={loadGroups}
      {...rest}
    />
  )
}
