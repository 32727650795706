import { useApolloLazyQuery } from 'API/services/Apollo'

import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import {
  EmployeeWithProfileFields,
  SuggestedEmployeesForCoverPickerQuery,
  SuggestedEmployeesForCoverPickerQueryData,
} from '../GraphQL'

export function useSuggestedEmployeesForCoverPicker() {
  const [
    loadInner,
    { loading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    SuggestedEmployeesForCoverPickerQueryData,
    Gateway.QuerySuggestedEmployeesForCoverByCursorArgs
  >(SuggestedEmployeesForCoverPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadSuggestedEmployeesForCover = ({
    scheduleId,
    inputValue,
  }: {
    scheduleId: string
    inputValue?: string
  }) => {
    loadInner({
      variables: {
        paging: { limit: PAGE_SIZE['10'] },
        scheduleId,
        filter: {
          ...(inputValue && {
            nameOrCode: Utils.GraphQL.ilikeFilter(inputValue),
          }),
        },
      },
    })
  }

  const refetchSuggestedEmployeesForCover = async ({
    inputValue,
  }: {
    inputValue?: string
  } = {}) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: {
        nameOrCode: inputValue
          ? Utils.GraphQL.ilikeFilter(inputValue)
          : undefined,
      },
    })
    return dataToNodes(result.data)
  }

  const suggestedEmployeesForCover = dataToNodes(data)

  return {
    suggestedEmployeesForCover,
    suggestedEmployeesForCoverLoading: loading,
    refetchSuggestedEmployeesForCover,
    loadSuggestedEmployeesForCover,
  }
}

function dataToNodes(
  data?: SuggestedEmployeesForCoverPickerQueryData,
): EmployeeWithProfileFields[] {
  return (
    data?.suggestedEmployeesForCoverByCursor?.edges.map(edge => edge.node) ?? []
  )
}
