import { useCallback } from 'react'

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { CreateShiftCoverMutation, ShiftCoversByCursorQuery } from './GraphQL'

export function useCreateShiftCover() {
  const t = useI18n<
    typeof TRANSLATIONS.shiftTradeCover.toasts.shiftCover.create
  >('shiftTradeCover.toasts.shiftCover.create')

  const [innerCreate, { loading: creating, error }] = useApolloMutation<
    MutationData<'createShiftCover'>,
    Gateway.MutationCreateShiftCoverArgs
  >(CreateShiftCoverMutation, {
    refetchQueries: compact([getOperationName(ShiftCoversByCursorQuery)]),
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createShiftCover = useCallback(
    async (input: Gateway.CreateShiftCoverInput) => {
      const result = await innerCreate({
        variables: {
          input,
        },
      })

      return Boolean(result.errors)
    },
    [innerCreate],
  )

  return { createShiftCover, creating, createError: error }
}
