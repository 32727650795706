import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { AuthService } from 'services/Auth'

import { AUTHENTICATE } from 'store/actions/auth'

import { useMount } from './useMount'

export function useAuth() {
  const [authState, setAuthState] = useState(AuthService.getState())
  const dispatch = useDispatch()

  useMount(() => {
    const handleAuthStateUpdate = (event: MessageEvent<string>) => {
      if (event.data === AuthService.UPDATE_AUTH_EVENT) {
        const updatedAuthState = AuthService.getState()
        setAuthState(updatedAuthState)

        // TODO: This is a temporary solution to update the auth state in the store
        //       until we can get rid of redux
        if (updatedAuthState.isAuthenticated) {
          dispatch({
            type: AUTHENTICATE.SUCCESS,
          })
        }
      }
    }

    window.addEventListener('message', handleAuthStateUpdate)

    return () => window.removeEventListener('message', handleAuthStateUpdate)
  })

  return authState
}
