import { DeepKeys } from '@tanstack/react-table'
import format from 'date-fns/format'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import frLocale from 'date-fns/locale/fr'
import I18n from 'i18n-js'

import { merge } from 'lodash'

import {
  getBrandConfig,
  isDawamyEnvironment,
  isDemoEnvironment,
  isEtfoEnvironment,
  isGardaEnvironment,
  isLotoQuebecEnvironment,
} from 'services/Brand'

// DEMO
import demoEn from './translations/demo/en.json'
// ETFO
import etfoEn from './translations/etfo/en.json'
// GARDA
import enGarda from './translations/garda/en.json'
import frGarda from './translations/garda/fr.json'
// LOTOQUEBEC
import lotoQuebecEn from './translations/lotoquebec/en.json'
import lotoquebecFr from './translations/lotoquebec/fr.json'
// MAIN
import mainEn from './translations/main/en.json'
import mainEs from './translations/main/es.json'
import mainFr from './translations/main/fr.json'
// DAWAMY
import enDawamy from './translations/nahdi-dawamy/en.json'
import frDawamy from './translations/nahdi-dawamy/fr.json'

/**
 * demoEn is used for applying custom wording, where required
 * Only for WA sales team to present using the Demo Account
 */

const { i18nBrandHook } = getBrandConfig()
const isDawamy = isDawamyEnvironment()
const isEtfo = isEtfoEnvironment()
const isGarda = isGardaEnvironment()
const isDemo = isDemoEnvironment()
const isLotoQuebec = isLotoQuebecEnvironment()

export const dateLocales = {
  en: enLocale,
  fr: frLocale,
  es: esLocale,
  projectsEn: enLocale,
}

function getTranslations() {
  if (isEtfo) {
    return {
      en: merge({}, mainEn, etfoEn),
      fr: mainFr,
      es: mainEs,
    }
  }

  if (isDawamy) {
    return {
      en: merge({}, mainEn, enDawamy),
      fr: merge({}, mainFr, frDawamy),
      es: mainEs,
    }
  }

  if (isGarda) {
    return {
      en: merge({}, mainEn, enGarda),
      fr: merge({}, mainFr, frGarda),
      es: mainEs,
    }
  }

  if (isLotoQuebec) {
    return {
      en: merge({}, mainEn, lotoQuebecEn),
      fr: merge({}, mainFr, lotoquebecFr),
      es: mainEs,
    }
  }

  if (isDemo) {
    return {
      en: merge({}, mainEn, demoEn),
      fr: mainFr,
      es: mainEs,
    }
  }

  return {
    en: mainEn,
    fr: mainFr,
    es: mainEs,
  }
}

I18n.fallbacks = true
I18n.translations = getTranslations()
I18n.missingTranslation = key => `![${key}]`

export const TRANSLATIONS = Object.freeze(mainEn)

export function isAmPm(locale: Locale) {
  return !!format(new Date(), 'LLL', { locale }).match(/a.m.|p.m./i)
}

export type TranslationKeys = DeepKeys<typeof TRANSLATIONS>
export function i18n(key: TranslationKeys, options?: any) {
  return I18n.t(key, options)
}

export default i18nBrandHook((translate: string, options: $TSFixMe = null) => {
  if (options?.custom) I18n.locale = options.brand
  return I18n.t(translate, options)
})
