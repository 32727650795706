import { SelectOptionWithColor } from 'Types/common'

export function shiftTagToOption(
  shiftTag: Pick<Gateway.ShiftTag, 'id' | 'name' | 'color'>,
): SelectOptionWithColor<number> {
  return {
    label: shiftTag.name,
    color: `#${shiftTag.color}`,
    value: shiftTag.id,
  }
}
