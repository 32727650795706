import { DateTime } from 'luxon'

import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { areasToFilters } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD = createAsyncAction('company/shifts/LOAD')
export const LOAD_MORE = createAsyncAction('company/shifts/LOAD_MORE')
export const DELETE = createAsyncAction('company/shifts/DELETE')
export const COPY = createAsyncAction('company/shifts/COPY')
export const CLEAR = 'company/shifts/CLEAR'

// TODO: remove after shiftsDailyPage will be refactored to use GraphQL (temporary solution)
const shiftsDailyPageInclude = [
  'shiftsJobs.schedules.employee.profile.profileAvatar',
  'shiftTags',
  'shiftsJobs.job',
  'branch',
]

const shiftsInclude = [
  'shiftsJobs.schedules.employee.profile.profileAvatar',
  'pauses',
  'shiftTags',
  'shiftsJobs.job',
  'branch',
  'shiftsJobs.schedules.acceptedShiftTrades.acceptedEmployee.profile',
  'shiftsJobs.schedules.fromShiftTrades.fromEmployee.profile',
  'shiftsJobs.schedules.fromShiftTrades.toSchedules.shift',
  'shiftsJobs.schedules.shiftCovers.fromEmployee.profile',
  'shiftsJobs.schedules.shiftCovers.toEmployees.profile',
  'shiftsJobs.schedules.timeEntry',
  'shiftsJobs.schedules.shift',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

// TODO apply cursor pagination on employee view schdule page & shift actions modal
const PAGE_SIZE = 999

export const loadShifts = ({
  from = null,
  to = null,
  areas = {},
  positions,
  shiftTags,
  shiftTagsFilterMode,
  more = false,
  page = 1,
  pageSize = PAGE_SIZE,
  timeZoneFilterMode = 'employees',
  noDispatch = false,
  departmentMode = 'manager',
  paged = true,
  optimizedInclude = false,
} = {}) => (dispatch, getState) => {
  const startDate = from || DateTime.utc().startOf('week')
  const endDate = to || DateTime.utc().endOf('week')
  const filterKey = timeZoneFilterMode === 'shifts' ? 'start_on' : 'range'

  const filter = {
    [filterKey]: {
      between: [startDate.toISODate(), endDate.toISODate()],
    },
    ...areasToFilters(areas),
  }

  if (positions) {
    filter.positions = positions
  }

  if (!isEmpty(shiftTags)) {
    filter.shift_tag_ids = {
      [shiftTagsFilterMode === 'or' ? 'any' : 'all']: shiftTags.map(
        shiftTag => shiftTag.value,
      ),
    }
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(getState())}/shifts`,
      types: !noDispatch && (more ? LOAD_MORE : LOAD),
      query: {
        include: optimizedInclude
          ? shiftsDailyPageInclude.join()
          : shiftsInclude.join(),
        display: departmentMode,
        filter,
        page: paged
          ? {
              number: page,
              size: pageSize,
            }
          : {},
      },
      paged,
    }),
  )
}

export const deleteShifts = shifts => dispatch => {
  if (isEmpty(shifts)) {
    return null
  }

  if (!isArray(shifts)) {
    throw new Error(
      '[store/actions/company/shifts/deleteShifts] Shifts should be passed as an array',
    )
  }

  const scheduleIds = []
  const deletedIds = []

  const data = map(shifts, shift => {
    deletedIds.push(shift.id)

    map(shift.shiftsJobs, shiftsJob => {
      forEach(shiftsJob.schedules, schedule => scheduleIds.push(schedule.id))
    })

    return {
      id: shift.id,
      type: 'shifts',
    }
  })

  return dispatch(
    apiCall({
      endpoint: `/shifts`,
      method: 'DELETE',
      query: { data },
      types: DELETE,
      payload: { scheduleIds, deletedIds },
    }),
  )
}

export const clearShifts = () => ({ type: CLEAR })
