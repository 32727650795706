import { Modifier } from 'react-day-picker'

import { DateTime } from 'luxon'

import isArray from 'lodash/isArray'
import isNaN from 'lodash/isNaN'

import { DayPickerState } from './types'

export function selectingFirstDay(
  from: DateTime | null,
  to: DateTime | null,
  day: Date,
): boolean {
  const selectedDateTime = DateTime.fromJSDate(day)

  const isBeforeFirstDay = from && selectedDateTime < from

  const isRangeSelected = !!(from && to)
  return !from || isBeforeFirstDay || isRangeSelected
}

export function stateToResult(state: DayPickerState, dayOnly: boolean) {
  const { from, to } = state

  if (dayOnly) return from ? from.toISODate() : null

  return {
    from: from ? from.toISODate() : null,
    to: to ? to.toISODate() : null,
  }
}

export const calculateIntervalFromMaxSelectionLength = (
  maxSelectionLength: string,
  localFrom?: DateTime | null,
) => {
  const interval: { after?: Date; before?: Date } = {}

  if (/month/.test(maxSelectionLength)) {
    const months = parseInt(maxSelectionLength, 10)

    interval.after = localFrom
      ? localFrom.plus({ months: isNaN(months) ? 1 : months }).toJSDate()
      : undefined
  } else if (/day/.test(maxSelectionLength)) {
    const days = parseInt(maxSelectionLength, 10)

    interval.after = localFrom
      ? localFrom.plus({ days: isNaN(days) ? 1 : days }).toJSDate()
      : undefined
  }
  return interval
}

export const getValidAfterDate = (
  disableFuture: boolean | { until: string },
) => {
  if (disableFuture && disableFuture !== true) {
    return { after: DateTime.fromISO(disableFuture.until).toJSDate() }
  }
  return { after: DateTime.local().toJSDate() }
}

export const getValidBeforeDate = (
  disablePast: boolean | { until: string },
) => {
  if (disablePast && disablePast !== true) {
    return { before: DateTime.fromISO(disablePast.until).toJSDate() }
  }
  return { before: DateTime.local().startOf('day').toJSDate() }
}

export const mergeDisabledDaysWithInterval = (
  disabledDays: Modifier[] | boolean,
  interval: { after?: Date; before?: Date },
) => {
  return isArray(disabledDays)
    ? [...(disabledDays as Modifier[]), interval]
    : disabledDays
}
