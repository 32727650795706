import React from 'react'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Flex } from 'components/ui/__v2__/Grid'
import { Color } from 'components/ui/__v3__/Badge/TimetypeBadge'

import { useI18n } from 'hooks/useI18n'

import { TRANSLATIONS } from 'i18n'

import { Container, Duration, Name, TimeType, Title } from './styles'

import { SimulationTotalItem } from '../../../../types'

type Props = {
  data: SimulationTotalItem[]
}

export function Total({ data }: Props) {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.simulationModal>(
    'weeklyTimesheets.simulationModal',
  )
  return (
    <Container>
      <Title>{t('data.total.title')}</Title>
      <Flex flexWrap="wrap" gap={4}>
        {data.map(item => (
          <TimeType key={item.name}>
            <Color color={item.color} />
            <Name>
              <TextOverflowWithPopover maxWidth={120}>
                {item.name}
              </TextOverflowWithPopover>
              :
            </Name>
            <Duration>{item.duration}</Duration>
          </TimeType>
        ))}
      </Flex>
    </Container>
  )
}
