import React from 'react'

import { FlexboxProps } from 'styled-system'

import { Span } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import { LoadMoreContainer, LoadMoreHandler } from './styles'

type Props = FlexboxProps & {
  hasMore: boolean
  fetchingMore: boolean
  onClick: () => void
}

export function LoadMoreStatus({
  hasMore,
  fetchingMore,
  onClick,
  ...flexBoxProps
}: Props) {
  const renderContent = () => {
    if (fetchingMore)
      return (
        <>
          <Spinner size={14} />
          <Span>{i18n('loadMore.loading')}</Span>
        </>
      )
    if (!hasMore) return <Span>{i18n('loadMore.noMoreData')}</Span>

    return (
      <LoadMoreHandler onClick={onClick}>
        {i18n('loadMore.load')}
      </LoadMoreHandler>
    )
  }

  return (
    <LoadMoreContainer {...flexBoxProps}>{renderContent()}</LoadMoreContainer>
  )
}
