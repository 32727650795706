import { useApproveTimeoffRequest } from './useApproveTimeoffRequest'
import { useCreateTimeoff } from './useCreateTimeoff'
import { useDeleteTimeoffsRequest } from './useDeleteTimeoffsRequest'
import { useMoveTimeoffRequest } from './useMoveTimeoffRequest'
import { useRejectTimeoffRequest } from './useRejectTimeoffRequest'
import { useTimeoff } from './useTimeoff'
import { useTimeoffsByCursor } from './useTimeoffsByCursor'
import { useTimeoffsByCursorGql } from './useTimeoffsByCursorGql'
import { useTimeoffsByOffset } from './useTimeoffsByOffset'

export const TimeoffsAPI = {
  approve: useApproveTimeoffRequest,
  reject: useRejectTimeoffRequest,
  delete: useDeleteTimeoffsRequest,
  move: useMoveTimeoffRequest,
  getByOffset: useTimeoffsByOffset,

  /** @deprecated use byCursor instead */
  getByCursor: useTimeoffsByCursor,

  byId: useTimeoff,
  byCursor: useTimeoffsByCursorGql,
  create: useCreateTimeoff,
}
export * from './types'
