import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { ShiftCoverState } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  ShiftCoversByCursorQuery,
  ShiftCoversByCursorQueryData,
} from './GraphQL'

type Props = {
  paging: CursorPage
  filter: {
    state?: ShiftCoverState

    employeeId?: string
  }
  sorting: Gateway.ShiftCoverSortingInput[]
}

export function useShiftCoversByCursor({ paging, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    ShiftCoversByCursorQueryData,
    Gateway.QueryShiftCoversByCursorArgs
  >(ShiftCoversByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...(filter?.state && {
          state: Utils.GraphQL.eqFilter(filter.state),
        }),
        ...(filter?.employeeId && {
          fromEmployeeId: Utils.GraphQL.eqFilter(filter.employeeId),
        }),
      },
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
    },

    onCompleted(data) {
      const newPageMeta = data.shiftCoversByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const shiftCovers = useMemo(
    () => data?.shiftCoversByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    shiftCovers,
    loading,
    currentPageMeta,
    refetch,
  }
}
