import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Flex } from 'components/ui/__v2__/Grid'

import { ENTER } from 'constants/keyCodes'

import useMount from 'hooks/useMount'

import { StyledTextArea } from './styles'

import { LabelText } from '../Input'

export function TextArea({
  autoFocus,
  value,
  style,
  onKeyDown,
  onSubmit,
  content,
  labelText,
  required,
  ...rest
}) {
  const textAreaRef = useRef(null)

  const focus = () => {
    if (textAreaRef) {
      textAreaRef.current.focus()
    }
  }

  useMount(() => {
    if (autoFocus) {
      focus()
    }
  })

  const handleKeyDown = event => {
    if (onKeyDown) {
      onKeyDown(event)
    }

    if (event.ctrlKey && event.keyCode === ENTER) {
      event.preventDefault()

      if (onSubmit) {
        onSubmit(event.target.value)
      }
    }
  }

  return (
    <Flex flexDirection="column">
      <LabelText content={content} labelText={labelText} required={required} />

      <StyledTextArea
        autoComplete="off"
        ref={textAreaRef}
        style={style}
        value={value || ''}
        onKeyDown={handleKeyDown}
        {...rest}
      />
    </Flex>
  )
}

TextArea.defaultProps = {
  autoFocus: false,
  content: '',
  labelText: '',
  value: null,
  required: false,
  style: null,
  onKeyDown: () => {},
  onSubmit: () => {},
}

TextArea.propTypes = {
  autoFocus: PropTypes.bool,
  content: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
}
