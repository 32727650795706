import { FiTag } from 'react-icons/fi'

import styled, { css } from 'styled-components'

export const TagsBadge = {
  Container: styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    width: 88px;
    height: 32px;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 4px;
    transition: all 0.17s ease-in-out;
    cursor: pointer;

    border: 1px solid ${({ theme }) => theme.selects.border.color.default};
    background-color: ${({ theme }) => theme.layout.bg};

    &:hover {
      border-color: ${({ theme }) => theme.selects.hover.border.color.default};
    }

    &:active {
      background-color: ${({ theme }) => theme.buttons.active.backgroundColor};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) =>
          theme.selects.backgroundColor.disabled};
      `}
  `,

  Icon: styled(FiTag)`
    width: 16px;
    height: 16px;
  `,
}
