import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

import { Button as ButtonBase } from 'components/ui/__v3__'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 483px;
`

export const Description = styled.p<MarginProps>`
  text-align: left;
  ${margin}
`

export const Button = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.WHISPER_GRAY};
`
