import styled from 'styled-components'

export const ValueContainerWrapper = styled.div`
  flex: 1;
  max-height: 80px;
  overflow: auto;
`

export const TimetypeItemContainer = styled.div`
  cursor: pointer;
`
