import React, { useMemo } from 'react'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { isDateInFuture, scheduleToOption } from './helpers'

export type SchedulesPickerProps = {
  employeeId?: string
  startDate?: string
  mayBeCovered?: boolean
  mayBeTraded?: boolean
  branchIds?: string[]
  hidePastShifts?: boolean
} & Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'isLoading'
  | 'options'
  | 'withPortal'
  | 'onMenuOpen'
>

export function SchedulesPicker({
  employeeId,
  startDate,
  mayBeCovered,
  mayBeTraded,
  branchIds,
  hidePastShifts,
  ...rest
}: SchedulesPickerProps) {
  const {
    schedules,
    schedulesPickerLoading,
    loadSchedules,
  } = API.Schedule.picker()

  const defaultOptions = useMemo(() => {
    const filteredSchedules = hidePastShifts
      ? schedules.filter(schedule =>
          isDateInFuture({
            date: schedule.shift.range.start,
            timezone: schedule.shift.location.settings.timezone,
          }),
        )
      : schedules

    return filteredSchedules.map(scheduleToOption)
  }, [schedules, hidePastShifts])

  const handleMenuOpen = () => {
    loadSchedules({
      employeeId,
      startDate,
      branchIds,
      mayBeCovered,
      mayBeTraded,
    })
  }

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect
      isLoading={schedulesPickerLoading}
      options={defaultOptions}
      withPortal
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}
