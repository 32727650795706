import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  padding: 12px 16px 16px 16px;
  gap: ${({ theme }) => theme.space[3]}px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.OUTLINE_BASE};
  background-color: ${({ theme }) => theme.colors.SLATE_GRAY};
  margin-top: 24px;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  font-weight: ${({ theme }) => theme.fontWeights[3]};
`

export const TimeType = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]}px;
`

export const Name = styled.span`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights[3]};
`

export const Duration = styled.span`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
`
