import { useApolloQuery } from 'API/services/Apollo'

import { TimeoffQuery, TimeoffQueryData } from './GraphQL'

export function useTimeoff(id: string) {
  const { data, loading, error } = useApolloQuery<
    TimeoffQueryData,
    Gateway.QueryTimeoffArgs
  >(TimeoffQuery, {
    variables: { id },
  })

  return {
    timeoff: data?.timeoff,
    loading,
    error,
  }
}
