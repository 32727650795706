import { useCallback, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  ShiftTradeSuggestedScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  ShiftTradeSuggestedSchedulesByCursorQuery,
  ShiftTradeSuggestedSchedulesQueryData,
} from '../GraphQL'

type Props = {
  paging: CursorPage
  filter: {
    scheduleId: string
    employeeIds?: string[]
    period?: {
      from: string
      to: string
    }
  }
  enabled?: boolean
}

export function useShiftTradeSuggestedSchedulesByCursor({
  paging,
  filter,
  enabled = true,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, fetchMore } = useApolloQuery<
    ShiftTradeSuggestedSchedulesQueryData,
    Gateway.QueryShiftTradeSuggestedSchedulesByCursorArgs
  >(ShiftTradeSuggestedSchedulesByCursorQuery, {
    skip: !enabled,
    fetchPolicy: 'network-only',
    variables: {
      scheduleId: filter.scheduleId,
      filter: {
        ...(filter.employeeIds && {
          employeeId: Utils.GraphQL.inFilter(filter.employeeIds),
        }),
        ...(filter.period && {
          startOn: Utils.GraphQL.betweenFilter({
            from: filter.period.from,
            to: filter.period.to,
          }),
        }),
      },
      sorting: [
        {
          direction: SortingDirection.Asc,
          field: ShiftTradeSuggestedScheduleSortingField.FromShiftStartAt,
        },
      ],
      paging: cursorPageToGraphqlPaging(paging),
    },

    onCompleted(data) {
      const newPageMeta = data.shiftTradeSuggestedSchedulesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const schedules =
    data?.shiftTradeSuggestedSchedulesByCursor?.edges.map(edge => edge.node) ??
    []

  const loadMore = useCallback(async () => {
    setLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setLoadingMore(false)
  }, [currentPageMeta.endCursor, paging.size, fetchMore])

  return {
    schedules,
    loadingInitial: loading && !loadingMore,
    currentPageMeta,
    loadingMore,
    loadMore,
  }
}
