import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'
import { TimeEntryReturn } from 'API/TimeCard/GraphQL'
import { DateTime } from 'luxon'

import { isEqual } from 'lodash'

import { useAppContext } from 'hooks'
import usePrevious from 'hooks/usePrevious'

import {
  dayToInterval,
  getFieldsByDay,
  serverToForm,
  validator,
} from '../helpers'
import { DraftTimesheetRecord, TimesheetFormState } from '../types'

type Args = {
  serverTimeEntries: TimeEntryReturn[]
  days: DateTime[]
}

export function useTimesheetForm({ serverTimeEntries, days }: Args) {
  const { company } = useAppContext()

  const defaultValues = useMemo(
    () => ({
      entries: serverTimeEntries.map(item => serverToForm(item, company)),
    }),
    [company, serverTimeEntries],
  )
  const isoDays = useMemo(() => days.map(day => day.toISODate()), [days])
  const prevDefaultValues = usePrevious(defaultValues)
  const prevIsoDays = usePrevious(isoDays)

  const schema = useMemo(() => validator(days.map(dayToInterval)), [days])

  const {
    control,
    setValue,
    reset,
    trigger,
    handleSubmit,
    getFieldState,
    getValues,
    unregister,
    resetField,
    formState: { isValid, errors },
  } = useForm<TimesheetFormState>({
    defaultValues,
    mode: 'onBlur',
    resolver: vestResolver(schema),
  })

  const isDirty = getFieldState('entries')?.isDirty

  useEffect(() => {
    if (
      !isEqual(defaultValues, prevDefaultValues) ||
      // Note: need to reset draft form values if we're looking at another day range
      !isEqual(isoDays, prevIsoDays)
    ) {
      reset(defaultValues)
      trigger()
    }
  }, [defaultValues, isoDays, prevDefaultValues, prevIsoDays, reset, trigger])

  const { fields } = useFieldArray({
    control,
    name: 'entries',
    keyName: 'formId',
  })

  const formTimeEntries = useWatch({
    control,
    name: 'entries',
  })

  const dirtyFormTimeEntries = useMemo(() => {
    return formTimeEntries.filter((_, index) => {
      const fieldState = getFieldState(`entries.${index}`)
      return fieldState.isDirty
    })
  }, [formTimeEntries, getFieldState])

  // Note: this is a way to workaround the limitation of 'field array methods' which make all fields clean
  // see https://github.com/react-hook-form/react-hook-form/issues/8309
  const append = useCallback(
    (data: DraftTimesheetRecord) => {
      setValue(`entries.${fields.length}`, data, {
        shouldDirty: true,
      })
      reset(getValues(), { keepDirty: true })
      trigger()
    },
    [fields.length, getValues, reset, setValue, trigger],
  )

  const remove = useCallback(
    (index: number) => {
      unregister(`entries.${index}`)
      // Handles the case when we're unregostering the last and only item
      const values = getValues()
      const resetValues = values?.entries ? values : { entries: [] }
      reset(resetValues, { keepDirty: true })
      trigger()
    },
    [getValues, reset, trigger, unregister],
  )

  const undo = useCallback(
    (index: number) => {
      resetField(`entries.${index}`, { keepDirty: false })
      reset(getValues(), { keepDirty: true })
      trigger()
    },
    [getValues, reset, resetField, trigger],
  )

  const resetForm = useCallback(() => {
    reset({ entries: [] }, { keepDirty: true })
    trigger()
  }, [reset, trigger])

  const fieldsByDay = useMemo(() => getFieldsByDay(fields, days), [
    days,
    fields,
  ])

  const allTimeEntriesAreDrafts =
    serverTimeEntries.length === 0 && formTimeEntries.length > 0

  return {
    control,
    fieldsByDay,
    formTimeEntries,
    dirtyFormTimeEntries,
    handleFormSubmit: handleSubmit,
    append,
    invalid: !isValid,
    getFieldState,
    remove,
    resetForm,
    trigger,
    hasUnsavedChanges: isDirty,
    allChangesSaved: !isDirty,
    allTimeEntriesAreDrafts,
    undo,
    errors,
  }
}
