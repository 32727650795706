import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { inputSelectFocusedCss } from 'components/styles'
import { when } from 'components/ui/__v3__/__utils__/styled-system'

export const StyledTextArea = styled.textarea`
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  vertical-align: middle;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.15s ease, background-color 0.15s ease;
  background-color: ${themeGet('input.bg.default')};
  caret-color: ${themeGet('input.caret')};
  border: 1px solid ${themeGet('input.border.default')};
  padding: 7px 8px;
  resize: vertical;
  max-height: 170px;
  min-height: 32px;

  ::placeholder {
    color: ${themeGet('input.placeholder')};
  }

  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track-piece,
  ::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &:invalid {
    box-shadow: none;
  }

  &:hover {
    border-color: ${themeGet('colors.MANATEE')};
  }

  :active,
  :focus {
    ${inputSelectFocusedCss}
  }

  ::placeholder {
    color: ${themeGet('colors.INPUT_PLACEHOLDER')};
    font-weight: normal;
    font-family: ${themeGet('font')};
  }

  ${when(
    'disabled',
    css`
      background-color: ${themeGet('input.bg.disabled')};
      border: 1px solid ${themeGet('input.border.disabled')};
      cursor: not-allowed;
      resize: none;
    `,
  )};

  ${props =>
    props.error &&
    css`
      border-bottom: 2px solid ${themeGet('colors.INPUT_ERROR')} !important;
    `};
`
