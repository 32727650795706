import React, { memo } from 'react'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Flex } from 'components/ui/__v2__/Grid'

import { LONG_DASH } from 'constants/schedule'

const POPOVER_PLACEMENT = 'right-end'

interface Props {
  text: string
}

function ShowMoreCell({ text }: Props) {
  return (
    <Flex alignItems="center" justifyContent="space-between" maxWidth={'100%'}>
      <TextOverflowWithPopover popoverPlacement={POPOVER_PLACEMENT}>
        {text ?? LONG_DASH}
      </TextOverflowWithPopover>
    </Flex>
  )
}

export default memo(ShowMoreCell)
