import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { Color } from './styles'

import { TextOverflowWithPopover } from '../../TextOverflowWithPopover'

type Props = {
  color: string
  label: string
}

export function ShiftTagItem({ label, color }: Props) {
  return (
    <Flex alignItems="center" gap={1}>
      <Color color={color} />
      <TextOverflowWithPopover popoverPlacement="right-end" width="100%">
        {label}
      </TextOverflowWithPopover>
    </Flex>
  )
}
