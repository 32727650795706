import styled from 'styled-components'
import { fontSize, FontSizeProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'
import { Spinner } from 'components/ui/Spinner'

export const ModalTitle = styled.div<FontSizeProps>`
  font-size: 18px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-align: center;
  margin-bottom: 12px;
  text-transform: ${either('upper', 'uppercase', 'initial')};

  ${fontSize};
`

interface ModalIconProps {
  disabled?: boolean
}
export const ModalIcon = styled.button<ModalIconProps>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 24px;
  margin-left: auto;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};

  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.text'),
  )};
`

export const StyledModalSpinner = styled(Spinner).attrs({
  size: 'large',
  primary: true,
})`
  position: absolute;
  top: 50%;
  left: 50%;
`
export const SpinnerContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
`
