import { useCallback } from 'react'

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { AcceptShiftTradeMutation, ShiftTradesByCursorQuery } from './GraphQL'

export function useAcceptShiftTrade() {
  const t = useI18n<
    typeof TRANSLATIONS.shiftTradeCover.toasts.shiftTrade.reject
  >('shiftTradeCover.toasts.shiftTrade.accept')

  const [innerAccept, { loading: accepting, error }] = useApolloMutation<
    MutationData<'acceptShiftTrade'>,
    Gateway.MutationAcceptShiftTradeArgs
  >(AcceptShiftTradeMutation, {
    refetchQueries: compact([getOperationName(ShiftTradesByCursorQuery)]),
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const acceptShiftTrade = useCallback(
    async (id: string) => {
      const result = await innerAccept({
        variables: {
          input: {
            id,
          },
        },
      })

      return Boolean(result.errors)
    },
    [innerAccept],
  )

  return { acceptShiftTrade, accepting, acceptError: error }
}
