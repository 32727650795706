import React from 'react'
import { components, OptionProps } from 'react-select'

import { TimetypeBadge } from 'components/ui/__v3__/Badge/TimetypeBadge'

import { TimetypeItemContainer } from './styles'

import { TimeTypePickerOption } from '../types'

export function TimetypeOption({
  data,
  ...rest
}: OptionProps<TimeTypePickerOption, true>) {
  return (
    <components.Option data={data} {...rest}>
      <TimetypeItemContainer>
        <TimetypeBadge color={data.color} label={data.label} />
      </TimetypeItemContainer>
    </components.Option>
  )
}
