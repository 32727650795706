/* eslint-disable no-console */

import { ACTIVITY_EVENTS } from './constants'
import {
  BroadcastMessage,
  BroadcastMessageType,
  SessionEventCallback,
  WorkerMessage,
  WorkerMessageType,
} from './types'
import { createSharedWorker, createWorker } from './utils'
import { SessionWorker, SharedSessionWorker } from './workers'

export class SessionManager {
  private workerPort?: MessagePort // Worker communication

  private broadcastChannel?: BroadcastChannel // Cross-tab communication

  private readonly BROADCAST_CHANNEL_NAME = 'session-auth-channel'

  private readonly LOCAL_STORAGE_CHANNEL = 'session-auth-storage-channel'

  private readonly ACTIVITY_CHECK_INTERVAL = 1000 * 60 // 1 minutes

  private timeoutId?: NodeJS.Timeout // Fallback timeout

  private activityCheckInterval?: NodeJS.Timeout

  private wasActive = false

  private isActivityPaused = false

  private sessionDuration = 0

  private callbacks: SessionEventCallback

  constructor(callbacks: SessionEventCallback, initialSessionDuration: number) {
    this.callbacks = callbacks
    this.sessionDuration = initialSessionDuration

    // Bind methods
    this.initializeSharedWorker = this.initializeSharedWorker.bind(this)
    this.handleWorkerMessage = this.handleWorkerMessage.bind(this)
    this.handleBroadcastMessage = this.handleBroadcastMessage.bind(this)
    this.initializeTimeoutFallback = this.initializeTimeoutFallback.bind(this)
    this.handleStorageEvent = this.handleStorageEvent.bind(this)
    this.handleUserActivity = this.handleUserActivity.bind(this)
    this.broadcastMessage = this.broadcastMessage.bind(this)
    this.cleanup = this.cleanup.bind(this)

    // Initialize all systems
    this.initializeSharedWorker()
    this.initializeBroadcastChannel()
    this.initializeActivityMonitoring()

    // Handle page unload
    window.addEventListener('unload', this.cleanup)
  }

  // ==============================================================================================
  // INITIALIZATION METHODS
  // ==============================================================================================

  // eslint-disable-next-line consistent-return
  private initializeSharedWorker() {
    const worker = createSharedWorker(SharedSessionWorker)

    if (!worker) {
      console.warn(
        'Shared Workers not supported, falling back to dedicated worker',
      )
      return this.initializeDedicatedWorker()
    }

    this.workerPort = worker.port
    this.workerPort.onmessage = (event: MessageEvent<WorkerMessage>) => {
      const { type } = event.data

      if (type === WorkerMessageType.TIMEOUT) {
        this.callbacks.onTimeout()
      }
    }

    this.workerPort.start()
    this.workerPort.postMessage({
      type: WorkerMessageType.START,
      payload: { duration: this.sessionDuration },
    })
  }

  // eslint-disable-next-line consistent-return
  private initializeDedicatedWorker() {
    const worker = createWorker(SessionWorker)

    if (!worker) {
      return this.initializeTimeoutFallback()
    }

    worker.onmessage = this.handleWorkerMessage
    this.workerPort = (worker as unknown) as MessagePort
  }

  private initializeTimeoutFallback() {
    this.startTimeoutCheck()
  }

  private startTimeoutCheck() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.timeoutId = setTimeout(() => {
      this.callbacks.onTimeout()
    }, this.sessionDuration * 1000)
  }

  private initializeBroadcastChannel() {
    try {
      this.broadcastChannel = new BroadcastChannel(this.BROADCAST_CHANNEL_NAME)
      this.broadcastChannel.onmessage = this.handleBroadcastMessage
    } catch (error) {
      this.initializeStorageFallback()
    }
  }

  private initializeStorageFallback() {
    window.addEventListener('storage', this.handleStorageEvent)
  }

  private initializeActivityMonitoring() {
    ACTIVITY_EVENTS.forEach(event => {
      window.addEventListener(event, this.handleUserActivity)
    })

    this.startActivityCheck()
  }

  private startActivityCheck() {
    this.activityCheckInterval = setInterval(async () => {
      if (this.wasActive) {
        this.wasActive = false
        this.isActivityPaused = false
        this.callbacks.onActivityCheck()
      }
    }, this.ACTIVITY_CHECK_INTERVAL)
  }

  // ==============================================================================================
  // EVENT HANDLING METHODS
  // ==============================================================================================
  // TODO: handle worker messages
  private handleWorkerMessage(event: MessageEvent<WorkerMessage>) {
    const { type } = event.data

    if (type === WorkerMessageType.TIMEOUT) {
      this.cleanup()
      this.callbacks.onTimeout()
    }
  }

  private handleBroadcastMessage(event: MessageEvent<BroadcastMessage>) {
    const { type, payload } = event.data

    switch (type) {
      case BroadcastMessageType.SESSION_UPDATED:
        if (payload) {
          this.sessionDuration = payload.duration
          this.callbacks.onSessionUpdate(payload)

          if (this.timeoutId) {
            this.startTimeoutCheck()
          }
        }
        break

      case BroadcastMessageType.LOGOUT:
        this.cleanup()
        this.callbacks.onTimeout()
        break

      default:
        break
    }
  }

  private handleStorageEvent(event: StorageEvent) {
    if (event.key !== this.LOCAL_STORAGE_CHANNEL) return

    try {
      const data = JSON.parse(event.newValue || '{}')
      this.handleBroadcastMessage({ data } as MessageEvent)
    } catch (error) {
      // Add sentry logging
    }
  }

  private handleUserActivity() {
    if (!this.isActivityPaused) {
      this.wasActive = true
      this.isActivityPaused = true
    }
  }

  // ==============================================================================================
  // PRIVATE HELPER METHODS
  // ==============================================================================================
  private broadcastMessage(message: BroadcastMessage) {
    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage(message)
    } else {
      // Fallback to localStorage
      localStorage.setItem(this.LOCAL_STORAGE_CHANNEL, JSON.stringify(message))
      localStorage.removeItem(this.LOCAL_STORAGE_CHANNEL)
    }
  }

  // ==============================================================================================
  // PUBLIC METHODS
  // ==============================================================================================

  public updateSession(state: Gateway.SessionState) {
    this.sessionDuration = state.duration

    if (this.workerPort) {
      this.workerPort.postMessage({
        type: WorkerMessageType.UPDATE,
        payload: { duration: state.duration },
      })
    } else {
      this.startTimeoutCheck()
    }

    this.broadcastMessage({
      type: BroadcastMessageType.SESSION_UPDATED,
      payload: state,
    })
  }

  public notifyLogout() {
    this.broadcastMessage({
      type: BroadcastMessageType.LOGOUT,
    })
  }

  // ==============================================================================================
  // CLEANUP METHODS
  // ==============================================================================================

  public cleanup() {
    window.removeEventListener('unload', this.cleanup)

    // Remove activity listeners
    ACTIVITY_EVENTS.forEach(event => {
      window.removeEventListener(event, this.handleUserActivity)
    })

    // Clean up worker
    if (this.workerPort) {
      this.workerPort.postMessage({ type: WorkerMessageType.DISCONNECT })

      if ('close' in this.workerPort) {
        this.workerPort.close()
      }
      this.workerPort = undefined
    }

    // Clean up broadcast channel
    if (this.broadcastChannel) {
      this.broadcastChannel.close()
      this.broadcastChannel = undefined
    }

    // Clean up timeout
    if (this.timeoutId) {
      clearInterval(this.timeoutId)
      this.timeoutId = undefined
    }

    if (this.activityCheckInterval) {
      clearInterval(this.activityCheckInterval)
      this.activityCheckInterval = undefined
    }

    // Clean up storage listener
    window.removeEventListener('storage', this.handleStorageEvent)
  }
}
