import React from 'react'
import { MultiValueProps } from 'react-select'

import { SelectOptionWithColor } from 'Types/common'

import { ShiftTag } from 'components/blocks/__v3__/ShiftTag'
import { Box } from 'components/ui/__v2__/Grid'

import Utils from 'services/Utils'

export function ShiftTagMultiValue({
  data,
  removeProps,
  ...rest
}: MultiValueProps<SelectOptionWithColor>) {
  return (
    <Box {...rest}>
      <ShiftTag
        label={data.label}
        maxWidth={200}
        tagColor={Utils.Color.removeHash(data.color)}
        onRemove={removeProps.onClick}
      />
    </Box>
  )
}
