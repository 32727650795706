import React from 'react'

import styled from 'styled-components'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { BoxProps, Flex } from 'components/ui/__v2__/Grid'

import { Utils } from 'services/Utils'

const Badge = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space[1]}px;
`

export const Color = styled.div<{ color: string }>`
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background: ${({ color }) => Utils.Color.stringToHex(color)};
`

type TimetypeBadgeProps = {
  color: string
  label: string
} & BoxProps
export function TimetypeBadge({
  color,
  label,
  ...badgeProps
}: TimetypeBadgeProps) {
  return (
    <Badge {...badgeProps}>
      <Color color={color} />

      <TextOverflowWithPopover popoverPlacement="right-end" width="100%">
        {label}
      </TextOverflowWithPopover>
    </Badge>
  )
}
