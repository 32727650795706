import { Company, JobEmployeeWithCompanySettings, Viewer } from 'Types/app'

import { isEmpty } from 'lodash'

import { CompanyIdentity, UserAccessLevels } from 'constants/ids'
import { Feature, Plan } from 'constants/products'

import { hasFeature } from 'helpers/access'

import Utils from 'services/Utils'

import {
  AppContextCompany,
  AppContextViewer,
  AppContextViewerManagementScope,
  AppContextViewerRole,
  AppContextViewerShiftTradeCoverScope,
} from './types'

export function prepareAppContextCompany({
  coreCompany,
  microserviceCompany,
  products,
}: {
  coreCompany?: Company
  microserviceCompany?: Gateway.Company
  products: Plan[]
}): AppContextCompany {
  return {
    coreId: coreCompany?.id ?? '',
    microserviceId: microserviceCompany?.id ?? '',

    identity: {
      Nahdi: coreCompany?.identity === CompanyIdentity.Nahdi,
      Etfo: coreCompany?.identity === CompanyIdentity.Etfo,
      Sobeys: coreCompany?.identity === CompanyIdentity.Sobeys,
      Demo: coreCompany?.identity === CompanyIdentity.Demo,
      Deluxe: coreCompany?.identity === CompanyIdentity.Deluxe,
      Keywords: coreCompany?.identity === CompanyIdentity.Keywords,
      Hrwize: coreCompany?.identity === CompanyIdentity.Hrwize,
      Aldo: coreCompany?.identity === CompanyIdentity.Aldo,
      Netease: coreCompany?.identity === CompanyIdentity.Netease,
      Lemon: coreCompany?.identity === CompanyIdentity.Lemon,
      Garda: coreCompany?.identity === CompanyIdentity.Garda,
      Fps: coreCompany?.identity === CompanyIdentity.Fps,
      Icc: coreCompany?.identity === CompanyIdentity.Icc,
      Tbs: coreCompany?.identity === CompanyIdentity.Tbs,
      LQ: coreCompany?.identity === CompanyIdentity.LQ,
    },

    settings: {
      autoShiftName: coreCompany?.settings?.autoShiftName ?? false,

      allowFutureManualTimecardApprove:
        coreCompany?.settings?.allowFutureManualTimecardApprove ?? false,

      allowDepartmentManagerMutualApprove:
        coreCompany?.settings?.allowDepartmentManagerMutualApprove ?? false,

      earlyClockIn: coreCompany?.settings?.earlyClockIn ?? false,
      earlyClockInLimit: coreCompany?.settings?.earlyClockInLimit,

      disableEmployeeProfileEdit:
        coreCompany?.settings?.disableEmployeeProfileEdit ?? false,

      managersCanArchiveEmployees:
        coreCompany?.settings?.managersCanArchiveEmployees ?? false,

      disableEmployeeBreaks:
        coreCompany?.settings?.disableEmployeeBreaks ?? false,

      sendScheduleEmails: coreCompany?.settings?.sendScheduleEmails ?? false,
      showWagesToManagers: coreCompany?.settings?.showWagesToManagers ?? false,
      calendarStartDay: coreCompany?.settings?.calendarStartDay ?? 1,

      rapidClockInMode: coreCompany?.settings?.rapidClockInMode,

      timeoffSettings: coreCompany?.settings?.timeoffSettings,
      timecardSettings: coreCompany?.settings?.timecardSettings,

      // Note: we will likely introduce a mechanism that will enable these selectively, for now we fallback to the presence of Intrgattions feature
      hasHierarchyExternalIds: hasFeature(Feature.Integrations, products),
      hasCvFieldExternalIds: hasFeature(Feature.Integrations, products),
      hasGroupExternalIds: hasFeature(Feature.Integrations, products),
      hasEmploymentTermsExternalIds: hasFeature(Feature.Integrations, products),
      hasTimeBucketExternalIds: hasFeature(Feature.Integrations, products),
      hasShiftValidation: hasFeature(Feature.ShiftValidation, products),
      hasGroups: hasFeature(Feature.Groups, products),
      hasShiftBidding: hasFeature(Feature.ShiftBidding, products),

      enableTimebuckets: hasFeature(Feature.TimeBuckets, products),
      canAssignShiftBeforeThreshold: hasFeature(
        Feature.ShiftEditOverrideForAssignment,
        products,
      ),
      allowShiftEditingThreshold: hasFeature(
        Feature.ShiftEditingThreshold,
        products,
      ),
      showLeaveBalanceInfo: hasFeature(Feature.LeaveBalanceInfo, products),
      canUnpublishSchedules: hasFeature(Feature.UnpublishSchedules, products),
      hasProfileEditControlToggle: hasFeature(
        Feature.ProfileEditControlToggle,
        products,
      ),
      canSimulateTimeClassificationInWeeklyTimesheet:
        hasFeature(
          Feature.WeeklyTimesheetTimeClassificationSimulation,
          products,
        ) &&
        hasFeature(Feature.WeeklyTimesheets, products) &&
        hasFeature(
          Feature.WeeklyTimesheetTimeClassificationSimulation,
          products,
        ),

      hasShiftTradeOnBehalf: hasFeature(Feature.ShiftTradeOnBehalf, products),
      hasShiftCoverOnBehalf: hasFeature(Feature.ShiftCoverOnBehalf, products),

      hasFilterEmployeesByBreakOrUpcomingShift: hasFeature(
        Feature.FilterEmployeesByBreakOrUpcomingShift,
        products,
      ),
      hasEditFutureBreaks: hasFeature(Feature.EditFutureBreaks, products),
    },
  }
}

export function prepareAppContextViewer(viewer: Viewer): AppContextViewer {
  const owner = viewer.accessLevel === UserAccessLevels.Owner
  const admin = viewer.accessLevel === UserAccessLevels.Admin
  const manager = viewer.accessLevel === UserAccessLevels.Manager
  const employee = viewer.accessLevel === UserAccessLevels.Employee

  const currentlyManager =
    viewer.currentAccessLevel === UserAccessLevels.Manager
  const currentlyEmployee =
    viewer.currentAccessLevel === UserAccessLevels.Employee

  const adminOrOwner = owner || admin
  const employer = owner || admin || manager

  const departmentManager =
    manager && viewer.managers?.[0].allManagedDepartments.length > 0
  const locationManager =
    manager && viewer.managers?.[0].allManagedBranches.length > 0

  const pureManager = manager && !viewer.employees?.length
  const pureEmployee = employee && !viewer.managers?.length

  const mutant = viewer.managers?.length > 0 && viewer.employees?.length > 0

  const jobsEmployees = (viewer.employees?.[0]?.jobsEmployees ??
    []) as JobEmployeeWithCompanySettings[]
  // NOTE: job employees with "deleted" state don't have company settings
  const activeJobsEmployees = jobsEmployees.filter(Utils.Role.activeRole)
  const timesheetJobsEmployees = activeJobsEmployees.filter(
    Utils.Role.timesheetRole,
  )
  const clockJobsEmployees = activeJobsEmployees.filter(Utils.Role.clockRole)

  const managementScope = identifyManagementScope(
    { adminOrOwner, manager },
    viewer,
  )

  const shiftTradeCoverScope = identifyShiftTradeCoverScope(
    adminOrOwner,
    viewer,
  )

  const canWritePersonalDetails = !!viewer.managers?.[0]?.permissions
    ?.writePersonalDetails

  return {
    ...viewer,

    shiftTradeCoverScope,
    managementScope,
    canWritePersonalDetails,
    // EE shift jobs divided by time capture method
    timesheetJobsEmployees,
    clockJobsEmployees,

    role: {
      owner,
      admin,
      adminOrOwner,
      manager,
      currentlyManager,
      pureManager,
      departmentManager,
      locationManager,

      employee,
      currentlyEmployee,
      pureEmployee,

      employer,
      mutant,
    },

    signedInAt: viewer.signedInAt ?? undefined,
    signedUpAt: viewer.signedUpAt ?? undefined,
  }
}

function identifyManagementScope(
  {
    adminOrOwner,
    manager,
  }: Pick<AppContextViewerRole, 'adminOrOwner' | 'manager'>,
  viewer: Viewer,
): AppContextViewerManagementScope {
  if (adminOrOwner)
    return {
      locationManagementScope: [],
      departmentManagementScope: [],
    }

  if (manager) {
    const managerLocationIds = viewer.managers?.[0].allManagedBranches.map(
      ({ branch }) => branch.id,
    )

    const managedDepartmentNodes = viewer.managers?.[0].allManagedDepartments.map(
      ({ branch, department }) => ({
        locationId: branch.id,
        departmentId: department.id,
      }),
    )
    const locationManagementScope = isEmpty(managerLocationIds)
      ? null
      : managerLocationIds

    const departmentManagementScope = isEmpty(managedDepartmentNodes)
      ? null
      : managedDepartmentNodes

    return {
      locationManagementScope,
      departmentManagementScope,
    }
  }

  return {
    locationManagementScope: null,
    departmentManagementScope: null,
  }
}

// NOTE: the function does not return an element if the setting is enabled in both places (Shift & Trade)
//       it finds the first one it encounters (Trade || Cover)
export function identifyShiftTradeCoverScope(
  adminOrOwner: boolean,
  viewer: Viewer,
): AppContextViewerShiftTradeCoverScope {
  if (adminOrOwner) {
    return { branchIdsForTrade: [], branchIdsForCover: [] }
  }

  const allManagedDepartments =
    viewer.managers?.[0]?.allManagedDepartments ?? []
  const allManagedBranches = viewer.managers?.[0]?.allManagedBranches ?? []

  const managedEntities = [...allManagedDepartments, ...allManagedBranches]

  const trade = new Set<string>()
  const cover = new Set<string>()

  managedEntities.forEach(managed => {
    const { tradeShift, findCover } = managed.branch.settings
    if (tradeShift) trade.add(managed.branch.id)
    if (findCover) cover.add(managed.branch.id)
  })

  return {
    branchIdsForTrade: Array.from(trade),
    branchIdsForCover: Array.from(cover),
  }
}
