import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { PEOPLE_KEY, TOGGLE_ROLES_TABLE_VIEW_EVENT } from './constants'
import { PeopleEvents, PeopleState } from './types'

export function usePeopleState() {
  const { [PEOPLE_KEY]: state, dispatch } = useStoreon<
    PeopleState,
    PeopleEvents
  >(PEOPLE_KEY)

  const people = state

  const setRolesTableView = useCallback(
    (payload: boolean) => dispatch(TOGGLE_ROLES_TABLE_VIEW_EVENT, payload),
    [dispatch],
  )

  return { setRolesTableView, people }
}
