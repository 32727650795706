import { generateRowsPerPageSelectOption } from 'helpers/pagination'

/** @deprecated Use PAGE_SIZE instead, refactor and delete this */
export const PAGE_SIZES = Object.freeze([10, 25, 50, 100] as const)

export const PAGE_SIZE = Object.freeze({
  '1': 1,
  '10': 10,
  '25': 25,
  '50': 50,
  '100': 100,
  '999': 999, // For cases we need all of it
} as const)

export const UNPAGINATED_PAGE_SIZE = PAGE_SIZE['100']

export type PaginationSizeOptions = typeof PAGE_SIZE[keyof typeof PAGE_SIZE]

export const ROWS_PER_PAGE_SELECT_OPTIONS = PAGE_SIZES.map(
  generateRowsPerPageSelectOption,
)

export enum FilterType {
  Search = 'SEARCH',
  Range = 'RANGE',
  Select = 'SELECT',
  Date = 'DATE',
}

// https://doug-martin.github.io/nestjs-query/docs/concepts/queries/#filter-reference
export enum FilterOperator {
  And = 'and',
  Or = 'or',

  Is = 'is',
  IsNot = 'isNot',

  Like = 'like',
  NotLike = 'notLike',
  ILike = 'iLike',
  NotILike = 'notILike',

  Equal = 'eq',
  NotEqual = 'neq',

  In = 'in',
  NotIn = 'notIn',

  LowerThan = 'lt',
  LowerThanEqual = 'lte',
  GreaterThan = 'gt',
  GreateThanEqual = 'gte',

  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
}
