import { IdToken } from '@auth0/auth0-spa-js'

export type AuthAppState = {
  from?: string
}

export interface IdTokenClaims extends IdToken {
  clusterId?: string
}

export type SessionEventCallback = {
  onTimeout: () => void
  onSessionUpdate: (state: Gateway.SessionState) => void
  onActivityCheck: () => void
}

// Make sure to keep this in sync with the WorkerMessageType enum in workers
export enum WorkerMessageType {
  START = 'START',
  TIMEOUT = 'TIMEOUT',
  UPDATE = 'UPDATE',
  DISCONNECT = 'DISCONNECT',
}

export type WorkerMessage = {
  type: WorkerMessageType
  payload?: {
    duration?: number
  }
}

export enum BroadcastMessageType {
  SESSION_UPDATED = 'SESSION_UPDATED',
  LOGOUT = 'LOGOUT',
}

export type BroadcastMessage = {
  type: BroadcastMessageType
  payload?: Gateway.SessionState
}
