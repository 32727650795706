import React, { Fragment } from 'react'

import { DateTime } from 'luxon'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Flex } from 'components/ui/__v2__/Grid'
import { Color } from 'components/ui/__v3__/Badge/TimetypeBadge'
import { Spinner } from 'components/ui/Spinner'

import { Container, DataRow, DataRowCell, DateRow, DateText } from './styles'

import { SimulationDataItem, SimulationDataRow } from '../../../../types'

type Props = {
  isLoading?: boolean
  data: SimulationDataItem[]
}

export function Rows({ isLoading, data }: Props) {
  const renderDataRow = (row: SimulationDataRow) => {
    const cells = [
      {
        key: 'timeType',
        content: (
          <>
            {row.color && <Color color={row.color} />}
            {row.timeTypeName && (
              <TextOverflowWithPopover>
                {row.timeTypeName}
              </TextOverflowWithPopover>
            )}
          </>
        ),
      },
      {
        key: 'startAt',
        content: <span>{row.startAt}</span>,
      },
      {
        key: 'endAt',
        content: <span>{row.endAt}</span>,
      },
      {
        key: 'duration',
        content: <span>{row.duration}</span>,
      },
    ]

    return cells.map(cell => (
      <DataRowCell
        justifyContent={cell.key === 'duration' ? 'center' : 'flexStart'}
        key={cell.key}
      >
        {cell.content}
      </DataRowCell>
    ))
  }

  if (isLoading) {
    return (
      <Container>
        <Flex alignItems="center" justifyContent="center" mt={3} width="100%">
          <Spinner primary size={30} />
        </Flex>
      </Container>
    )
  }

  return (
    <Container>
      {data.map(item => {
        return (
          <Fragment key={item.date}>
            <DateRow>
              <DateText>
                {DateTime.fromISO(item.date).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY,
                )}
              </DateText>
            </DateRow>
            {item.rows.map((field, index) => (
              <DataRow key={index}>{renderDataRow(field)}</DataRow>
            ))}
          </Fragment>
        )
      })}
    </Container>
  )
}
